import { Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { DeepPartial } from 'react-hook-form';
import {
  CourseReimbursementModule,
  Reimbursement,
  ReimbursementStatus,
} from '../../interfaces/reimbursment';
import { formatCurrency } from '../../libs/utils/formatters/currency';
import { formatDate } from '../../libs/utils/formatters/datetimeFormatter';
import StatusBadge from './ReimbursementStatusBadge';
import { styles } from './styles';

interface CourseReimbursementPDFProps {
  reimbursement: Reimbursement;
}

const CourseReimbursementPDF: React.FC<CourseReimbursementPDFProps> = ({
  reimbursement,
}) => {
  const courseModule = reimbursement.attributes
    .module as DeepPartial<CourseReimbursementModule>;

  const role = reimbursement.attributes.treasurerCourse?.data
    ? 'Tesoriere'
    : 'Responsabile';

  const getCourse = () => {
    if (role === 'Tesoriere') {
      return reimbursement.attributes.treasurerCourse?.data?.attributes;
    }
    return reimbursement.attributes.supervisorCourse?.data?.attributes;
  };

  const getPaymentDate = () => {
    if (role === 'Tesoriere') {
      return reimbursement.attributes.treasurerCourse?.data?.attributes
        ?.treasurerPaymentDate;
    }
    return reimbursement.attributes.supervisorCourse?.data?.attributes
      ?.supervisorPaymentDate;
  };

  const getEstimatedPayment = () => {
    const course = getCourse();
    if (role === 'Tesoriere') {
      return course?.treasurerAmount;
    }
    return course?.supervisorAmount;
  };

  const course = getCourse();
  const paymentDate = getPaymentDate();
  const estimatedPayment = getEstimatedPayment();

  const isPaid = !!paymentDate;
  const status = isPaid
    ? ('PAGATO' as const)
    : (reimbursement.attributes.status as ReimbursementStatus);

  const submissionDate = formatDate(reimbursement.attributes.createdAt, 'L');

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        {/* Meta information (status and submission date) */}
        <View style={styles.meta}>
          <View style={styles.metaRow}>
            <View>
              <Text style={styles.label}>Data invio</Text>
              <Text style={styles.value}>{submissionDate}</Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <StatusBadge status={status} />
            </View>
          </View>
        </View>

        {/* Course Details */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Dettagli Corso</Text>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Titolo</Text>
              <Text style={styles.value}>{course?.title}</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>Data inizio</Text>
              <Text style={styles.value}>
                {formatDate(course?.startDate || '', 'LL')}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Compenso preventivo</Text>
              <Text style={styles.value}>
                {formatCurrency(estimatedPayment || 0)}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>Ruolo</Text>
              <Text style={styles.value}>{role}</Text>
            </View>
          </View>
          {paymentDate && (
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={styles.label}>Data pagamento</Text>
                <Text style={styles.value}>{formatDate(paymentDate, 'L')}</Text>
              </View>
            </View>
          )}
        </View>

        {/* Personal Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Dati Personali</Text>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Nome e Cognome</Text>
              <Text style={styles.value}>
                {`${courseModule.personalInfo?.firstName} ${courseModule.personalInfo?.lastName}`}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>Codice Fiscale</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.fiscalCode}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Indirizzo</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.address}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>Civico</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.streetNumber}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Città</Text>
              <Text style={styles.value}>
                {`${courseModule.personalInfo?.city} (${courseModule.personalInfo?.province}) ${courseModule.personalInfo?.zip}`}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>Email</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.email}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>Telefono</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.phone}
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>IBAN</Text>
              <Text style={styles.value}>
                {courseModule.personalInfo?.iban}
              </Text>
            </View>
          </View>
        </View>

        {/* Payment */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Compenso</Text>
          {courseModule.payment?.waivePayment ? (
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={styles.label}>Rinuncia al compenso</Text>
                <Text style={styles.value}>
                  Il docente ha rinunciato al compenso
                </Text>
              </View>
            </View>
          ) : (
            <>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>Importo</Text>
                  <Text style={styles.value}>
                    {formatCurrency(courseModule.payment?.amount || 0)}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>Corso</Text>
                  <Text style={styles.value}>
                    {courseModule.payment?.courseName}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>Data Corso</Text>
                  <Text style={styles.value}>
                    {formatDate(
                      courseModule.payment?.courseStartDate || '',
                      'L'
                    )}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>Sezione</Text>
                  <Text style={styles.value}>
                    {courseModule.payment?.organizingSection}
                  </Text>
                </View>
              </View>

              {/* Declarations */}
              <View style={{ marginTop: 10 }}>
                <Text style={[styles.label, { marginBottom: 5 }]}>
                  Dichiarazioni
                </Text>
                <Text style={styles.value}>
                  Dipendente pubblico:{' '}
                  {courseModule.payment?.declarations?.isPublicEmployee ===
                  'YES'
                    ? 'Sì'
                    : 'No'}
                </Text>

                {Object.entries(
                  courseModule.payment?.declarations?.autonomousWork || {}
                ).map(([key, value]) => (
                  <Text key={key} style={styles.value}>
                    {value ? '✓' : '✗'}{' '}
                    {
                      {
                        noTimeConstraints:
                          'Il sottoscritto non ha avuto obblighi di orario',
                        discretionaryExecution:
                          "Il sottoscritto è stato pienamente libero di svolgere l'incarico con discrezionalità nei riguardi delle modalità e dei tempi di esecuzione",
                        independentCriteria:
                          "I criteri seguiti nell'effettuare la prestazione sono stati del tutto indipendenti e personali, avendo il richiedente solo indicato il contenuto della prestazione richiesta",
                        agreedCompensation:
                          "la remunerazione è quella dovuta sulla base dell'offerta concordata",
                      }[key]
                    }
                  </Text>
                ))}

                {Object.entries(courseModule.payment?.declarations || {})
                  .filter(
                    ([key]) =>
                      !['autonomousWork', 'isPublicEmployee', 'year'].includes(
                        key
                      )
                  )
                  .map(([key, value]) => (
                    <Text key={key} style={styles.value}>
                      {value ? '✓' : '✗'}{' '}
                      {
                        {
                          taskCompletion:
                            "di aver svolto l'incarico/chi sopra riportato/i",
                          vatExemption:
                            "che non esercito per professione abituale attività di lavoro autonomo e che il compenso non è soggetto ad IVA (escluso ai sensi dell'art.5 del D.P.R. 633/72)",
                          taxWithholding:
                            "che il compenso è soggetto a ritenuta di acconto ai sensi dell'art.25 del D.P.R. 600/73",
                          socialSecurity:
                            "che tale compenso non è assoggettato a contributo previdenziale ai sensi dell'art. 44 D.L. 269/209 in quanto nel corso dell'anno solare sotto indicato il totale dei compensi ricevuti dal prestatore non supera i 5.000,00€",
                          noEmploymentClaim:
                            'che la suddetta prestazione resa occasionalmente, esclude da parte del dichiarante qualsiasi pretesa di riconoscimento di rapporto di lavoro dipendente',
                        }[key]
                      }
                      {key === 'socialSecurity' &&
                        ` (Anno ${courseModule.payment?.declarations?.year})`}
                    </Text>
                  ))}
              </View>
            </>
          )}
        </View>

        {/* Summary */}
        <View style={styles.summary}>
          <Text style={styles.sectionTitle}>Riepilogo Totali</Text>
          <View style={styles.summaryRow}>
            <Text style={styles.label}>TOTALE LORDO COMPENSO</Text>
            <Text style={styles.value}>
              {formatCurrency(
                courseModule.payment?.waivePayment
                  ? 0
                  : courseModule.payment?.amount || 0
              )}
            </Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.label}>RITENUTA D'ACCONTO</Text>
            <Text style={styles.value}>
              {formatCurrency(
                (courseModule.payment?.waivePayment
                  ? 0
                  : courseModule.payment?.amount || 0) * 0.2
              )}
            </Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.label}>TOTALE NETTO</Text>
            <Text style={styles.value}>
              {formatCurrency(
                (courseModule.payment?.waivePayment
                  ? 0
                  : courseModule.payment?.amount || 0) * 0.8
              )}
            </Text>
          </View>
        </View>

        {/* Note if exists */}
        {reimbursement.attributes.note && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Note</Text>
            <Text style={styles.value}>{reimbursement.attributes.note}</Text>
          </View>
        )}

        {/* Signature */}
        <View style={styles.signature}>
          <Text style={styles.label}>Firma</Text>
          <Text style={styles.value}>{courseModule.signature}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CourseReimbursementPDF;
