import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  listReimbursements,
  createAdminCourseReimbursement,
} from '../../../api/reimbursement';
import CourseReimbursementsTable from '../Tables/CourseReimbursementsTable';
import Pagination from '../../../components/Pagination';
import { useAuth } from '../../../contexts/Auth';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import FiltersBar from '../../../components/Filters/FiltersBar';
import { FilterType } from '../../../interfaces/filters';
import sectionsAPIs from '../../../api/section';
import {
  ReimbursementAttributes,
  ReimbursementStatus,
} from '../../../interfaces/reimbursment';
import AdminCourseReimbursementFormModal from '../../../components/Modals/ReimbursmentFormModal/AdminCourseReimbursementFormModal';
import { prepareReimbursementFormData } from '../../../components/Modals/ReimbursmentFormModal/utils';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

const CourseReimbursements: React.FC = () => {
  const [{ token }] = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const reimbursementsQuery = useQuery({
    queryKey: ['course-reimbursements', searchParams.toString(), 'course'],
    queryFn: () => listReimbursements('course', searchParams, token),
  });

  const createMutation = useMutation(
    (data: FormData) => createAdminCourseReimbursement(data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('course-reimbursements');
        toast.success('Rimborso creato con successo');
        setIsCreateModalOpen(false);
      },
      onError: () => {
        toast.error('Errore durante la creazione del rimborso');
      },
    }
  );

  const handleCreate = async (data: ReimbursementAttributes, files: File[]) => {
    data.module.payment.amount = data.totalCompensation;
    const formData = prepareReimbursementFormData(data, files);
    await createMutation.mutateAsync(formData);
  };

  return (
    <>
      <TitlePage title='Compensi e rimborsi corsi' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <div className='mt-12 space-y-4'>
          <div className='flex flex-row items-center justify-between'>
            <H1Styled noMargin>Compensi e rimborsi corsi</H1Styled>
            <PrimaryButton onClick={() => setIsCreateModalOpen(true)}>
              Crea
            </PrimaryButton>
          </div>

          <FiltersBar
            filters={[
              {
                attribute: 'search',
                type: FilterType.SEARCH_BAR,
                label: 'Cerca tesoriere o responsabile...',
              },
              {
                type: FilterType.DATE,
                label: 'Data corso',
                attribute: 'courseDate',
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'sections',
                label: 'Sezione',
                key: 'sectionsListFilters',
                searchForAttributes: ['name'],
                source: (data: any) =>
                  sectionsAPIs.findOptions({
                    ...data,
                    query: { ...data.query },
                  }),
              },
              {
                attribute: 'courseTitle',
                type: FilterType.SEARCH_BAR,
                label: 'Titolo corso...',
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'role',
                label: 'Ruolo',
                key: 'roleFilter',
                source: () => ({
                  data: [
                    {
                      id: 'treasurer',
                      attributes: {
                        name: 'Tesoriere',
                      },
                    },
                    {
                      id: 'supervisor',
                      attributes: {
                        name: 'Responsabile',
                      },
                    },
                  ],
                }),
              },
              {
                type: FilterType.DATE,
                label: 'Data pagamento',
                attribute: 'paymentDate',
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'status',
                label: 'Stato',
                key: 'statusFilter',
                source: () => ({
                  data: [
                    {
                      id: ReimbursementStatus.WAITING_COMPLETION,
                      attributes: {
                        name: 'Bozza',
                      },
                    },
                    {
                      id: ReimbursementStatus.IN_REVIEW,
                      attributes: {
                        name: 'Da approvare',
                      },
                    },
                    {
                      id: ReimbursementStatus.REJECTED,
                      attributes: {
                        name: 'Documentazione incompleta',
                      },
                    },
                    {
                      id: ReimbursementStatus.APPROVED,
                      attributes: {
                        name: 'Approvato',
                      },
                    },
                    {
                      id: 'PAID',
                      attributes: {
                        name: 'Pagato',
                      },
                    },
                  ],
                }),
              },
            ]}
          />

          <CourseReimbursementsTable
            reimbursementsQuery={reimbursementsQuery}
            searchingWords={[searchParams.get('search') || '']}
            searchParams={searchParams}
          />

          {reimbursementsQuery.data && (
            <Pagination
              pagination={reimbursementsQuery.data?.meta?.pagination}
            />
          )}
        </div>
      </div>

      <AdminCourseReimbursementFormModal
        key={isCreateModalOpen ? 'open' : 'closed'}
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreate}
      />
    </>
  );
};

export default CourseReimbursements;
