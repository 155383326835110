import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React from 'react';
import { CourseLessonResponse } from '../../../interfaces/lesson';
import { ReimbursementAttributes } from '../../../interfaces/reimbursment';
import CourseSearchSelect from '../../FetchSelect/CourseSearchSelect';
import LessonSearchSelect from '../../FetchSelect/LessonSearchSelect';
import InputPresentational from '../../Form/InputPresentational';
import AdminBaseReimbursementFormModal from './AdminBaseReimbursementFormModal';
import { useReimbursementForm } from './hooks/useReimbursementForm';
import { adminLessonReimbursementSchema } from './validation';

interface AdminLessonReimbursementFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ReimbursementAttributes, files: File[]) => Promise<void>;
  initialData?: Partial<ReimbursementAttributes>;
  isEdit?: boolean;
}

const AdminLessonReimbursementFormModal: React.FC<
  AdminLessonReimbursementFormModalProps
> = ({ isOpen, onClose, onSubmit, initialData, isEdit = false }) => {
  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue,
    control,
    isSubmitting,
    files,
    handleFileChange,
    handleDrop,
    handleRemovePending,
  } = useReimbursementForm({
    initialData,
    onSubmit,
    onClose,
    resolver: yupResolver(adminLessonReimbursementSchema),
  });

  const handleLessonSelect = (lesson: CourseLessonResponse) => {
    setValue(
      'module.payment.courseName',
      lesson.attributes.course?.data?.attributes?.title || ''
    );
    setValue('module.payment.lessonName', lesson.attributes.title || '');
    setValue(
      'module.payment.lessonDate',
      moment(lesson.attributes.date).format('YYYY-MM-DD')
    );
    setValue(
      'module.payment.organizingSection',
      lesson.attributes.course?.data?.attributes?.section?.data?.attributes
        ?.name || ''
    );
  };

  const lessonCourse = watch('lessonCourse');

  return (
    <AdminBaseReimbursementFormModal
      control={control}
      isOpen={isOpen}
      onClose={onClose}
      title={
        isEdit
          ? 'Modifica rendicontazione lezione'
          : 'Nuova rendicontazione lezione'
      }
      isEdit={isEdit}
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      watch={watch}
      setValue={setValue}
      isSubmitting={isSubmitting}
      files={files}
      handleFileChange={handleFileChange}
      handleDrop={handleDrop}
      handleRemovePending={handleRemovePending}
    >
      {/* Lesson Selection */}

      <div className='bg-white p-6 rounded-lg space-y-4'>
        <h4 className='text-lg font-medium text-gray-900'>Seleziona Corso</h4>
        <CourseSearchSelect
          name='lessonCourse'
          control={control}
          label='Corso'
          onCourseSelect={(course) => {
            if (course.id !== lessonCourse) {
              setValue('lesson', null);
            }
          }}
        />
        <h4 className='text-lg font-medium text-gray-900'>Seleziona Lezione</h4>
        <LessonSearchSelect
          name='lesson'
          control={control}
          label='Lezione'
          error={errors.lesson}
          required
          lessonCourse={lessonCourse}
          onLessonSelect={handleLessonSelect}
        />
      </div>

      {/* Payment Information */}
      <div className='bg-white p-6 rounded-lg space-y-4'>
        <h4 className='text-lg font-medium text-gray-900'>Dati Pagamento</h4>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <InputPresentational
            id='totalCompensation'
            label='Importo compenso *'
            name='totalCompensation'
            type='text'
            register={register}
            error={errors.totalCompensation}
          />
          <InputPresentational
            id='totalReimbursement'
            label='Importo rimborso *'
            name='totalReimbursement'
            type='text'
            register={register}
            error={errors.totalReimbursement}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.courseName'
            label='Nome corso *'
            name='module.payment.courseName'
            type='text'
            register={register}
            error={errors.module?.payment?.courseName}
            readOnly={true}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.lessonName'
            label='Nome lezione *'
            name='module.payment.lessonName'
            type='text'
            register={register}
            error={(errors.module?.payment as any)?.lessonName}
            readOnly={true}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.organizingSection'
            label='Sezione organizzatrice *'
            name='module.payment.organizingSection'
            type='text'
            register={register}
            error={errors.module?.payment?.organizingSection}
            readOnly={true}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.lessonDate'
            label='Data lezione *'
            name='module.payment.lessonDate'
            type='date'
            register={register}
            error={(errors.module?.payment as any)?.lessonDate}
            readOnly={true}
          />
        </div>
      </div>

      <div className='bg-white p-6 rounded-lg space-y-4'>
        <h4 className='text-lg font-medium text-gray-900'>Allegati</h4>
        <div className='space-y-4'>
          <div className='flex items-center justify-center w-full'>
            <label
              htmlFor='dropzone-file'
              className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100'
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                <svg
                  className='w-8 h-8 mb-4 text-gray-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 20 16'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                  />
                </svg>
                <p className='mb-2 text-sm text-gray-500'>
                  <span className='font-semibold'>Clicca per caricare</span> o
                  trascina i file qui
                </p>
                <p className='text-xs text-gray-500'>
                  Solo file PDF o immagini
                </p>
              </div>
              <input
                id='dropzone-file'
                type='file'
                className='hidden'
                multiple
                onChange={handleFileChange}
                accept='.pdf,image/*'
              />
            </label>
          </div>

          {/* File List */}
          <div className='space-y-2'>
            {files.existing.map((file) => (
              <div
                key={file.id}
                className='flex items-center justify-between p-2 bg-gray-50 rounded-lg'
              >
                <span className='text-sm text-gray-600'>
                  {file.attributes.name}
                </span>
                <a
                  href={file.attributes.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary hover:text-primary-dark'
                >
                  Visualizza
                </a>
              </div>
            ))}
            {files.pending.map((file, index) => (
              <div
                key={index}
                className='flex items-center justify-between p-2 bg-gray-50 rounded-lg'
              >
                <span className='text-sm text-gray-600'>{file.name}</span>
                <button
                  type='button'
                  onClick={() => handleRemovePending(index)}
                  className='text-red-500 hover:text-red-700'
                >
                  Rimuovi
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AdminBaseReimbursementFormModal>
  );
};

export default AdminLessonReimbursementFormModal;
