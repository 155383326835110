import React from 'react';
import { UseQueryResult, useMutation } from 'react-query';
import { match } from 'ts-pattern';
import { Reimbursement } from '../../../interfaces/reimbursment';
import CourseReimbursementRow from './CourseReimbursementRow';
import { StrapiResponseList } from '../../../interfaces/commons';
import { downloadCourseReimbursementsCSV } from '../../../api/reimbursement';
import { useAuth } from '../../../contexts/Auth';
import { DownloadIcon } from '@heroicons/react/outline';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

interface CourseReimbursementsTableProps {
  reimbursementsQuery: UseQueryResult<
    StrapiResponseList<Reimbursement>,
    unknown
  >;
  searchingWords?: string[];
  searchParams: URLSearchParams;
}

const CourseReimbursementsTable: React.FC<CourseReimbursementsTableProps> = ({
  reimbursementsQuery,
  searchingWords,
  searchParams,
}) => {
  const [{ token }] = useAuth();
  const { mutate: downloadCSV, isLoading: isDownloading } = useMutation(
    'downloadCourseReimbursementsCSV',
    () => downloadCourseReimbursementsCSV(searchParams, token)
  );

  const tableElements = [
    'Profilo',
    'Data invio',
    'Data inizio',
    'Sezione',
    'Corso',
    'Ruolo',
    'Compenso lordo',
    'Data pagamento',
    'Stato',
    'Nota',
    'Dettagli',
  ];

  return (
    <>
      <div className='flex justify-end w-full'>
        <PrimaryButton
          textSmall
          disabled={isDownloading}
          isLoading={isDownloading}
          onClick={() => downloadCSV()}
        >
          <span className='text-sm underline flex flex-row'>
            <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
          </span>
        </PrimaryButton>
      </div>

      {match(reimbursementsQuery)
        .with({ status: 'success' }, ({ data: reimbursementsResponse }) => {
          return (
            <div className='-my-2 overflow-x-auto'>
              <div className='inline-block min-w-full py-2 align-middle'>
                <div className='overflow-hidden ring-1 border ring-black ring-opacity-5'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, idx) => (
                          <th
                            key={idx}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {reimbursementsResponse?.data
                        ?.sort(
                          (a, b) =>
                            new Date(b.attributes.createdAt).getTime() -
                            new Date(a.attributes.createdAt).getTime()
                        )
                        .map((reimbursement) => (
                          <CourseReimbursementRow
                            key={reimbursement.id}
                            reimbursement={reimbursement}
                            searchWords={searchingWords || []}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })
        .with({ status: 'error' }, () => {
          return <p>Errore caricamento rimborsi...</p>;
        })
        .with({ status: 'loading' }, { status: 'idle' }, () => {
          return <p>Caricamento rimborsi...</p>;
        })
        .exhaustive()}
    </>
  );
};

export default CourseReimbursementsTable;
