import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Attachment,
  ReimbursementAttributes,
} from '../../../../interfaces/reimbursment';

interface FileState {
  existing: Attachment[];
  pending: File[];
}

interface UseReimbursementFormProps {
  initialData?: Partial<ReimbursementAttributes>;
  onSubmit: (data: ReimbursementAttributes, files: File[]) => Promise<void>;
  onClose: () => void;
  resolver?: any;
}

export const useReimbursementForm = ({
  initialData,
  onSubmit,
  onClose,
  resolver,
}: UseReimbursementFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<FileState>({
    existing: initialData?.attachments?.data || [],
    pending: [],
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm<ReimbursementAttributes>({
    resolver,
    defaultValues: {
      module: initialData?.module || {},
      note: initialData?.note || '',
      totalCompensation: initialData?.totalCompensation || 0,
      totalReimbursement: initialData?.totalReimbursement || 0,
      lesson: initialData?.lesson,
      profile: initialData?.profile,
      supervisorCourse: initialData?.supervisorCourse,
      treasurerCourse: initialData?.treasurerCourse,
      lessonCourse: initialData?.lessonCourse,
      reimbursementViaInvoice: false,
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []);
    handleFiles(newFiles);
  };

  const handleFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(
      (file) =>
        file.type === 'application/pdf' || file.type.startsWith('image/')
    );
    if (validFiles.length !== newFiles.length) {
      toast.error('Solo file PDF e immagini sono accettati');
    }
    setFiles((prev) => ({
      ...prev,
      pending: [...prev.pending, ...validFiles],
    }));
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleRemovePending = (index: number) => {
    setFiles((prev) => ({
      ...prev,
      pending: prev.pending.filter((_, i) => i !== index),
    }));
  };

  const handleFormSubmit = async (data: ReimbursementAttributes) => {
    setIsSubmitting(true);
    try {
      const toSubmit = {
        ...data,
        module: {
          ...data.module,
          ADMIN_MANAGED: true,
        },
        totalCompensation:
          data.totalCompensation ?? data.module.payment?.amount ?? 0,
        totalReimbursement:
          data.totalReimbursement ??
          ('refund' in data.module ? data.module.refund?.totalAmount ?? 0 : 0),
      };
      await onSubmit(toSubmit, files.pending);
      onClose();
      reset();
      setFiles({ existing: [], pending: [] });
    } catch (error) {
      toast.error('Errore durante il salvataggio');
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(handleFormSubmit),
    watch,
    setValue,
    control,
    errors,
    isSubmitting,
    files,
    handleFileChange,
    handleDrop,
    handleRemovePending,
  };
};
