import { ReimbursementAttributes } from '../../../interfaces/reimbursment';

export const prepareReimbursementFormData = (
  data: ReimbursementAttributes,
  files: File[]
): FormData => {
  const formData = new FormData();

  // Add the main data as JSON
  formData.append('data', JSON.stringify(data));

  // Add files
  files.forEach((file, index) => {
    formData.append(`files.attachments`, file);
  });

  return formData;
};
