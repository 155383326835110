import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useSearchParams } from 'react-router-dom';
import { BackspaceIcon } from '@heroicons/react/outline';
import { FilterType } from '../../interfaces/filters';
import _ from 'lodash';
import SelectFilter from './components/SelectFilter';
import qs from 'qs';
import DateFilter from './components/DateFilter';

interface Filter {
  attribute: string;
  type: FilterType;
  label: string;
  key?: string;
  source?: any;
  searchForAttributes?: string[];
  isSearchable?: boolean;
  isDisabled?: boolean;
  defaultValues?: Partial<SelectedOption>[];
  isMutualEsclusion?: boolean;
}

interface SelectedOption {
  value: string;
  label: string;
  attribute: string;
  type?: string;
  isDisabled?: string;
}

interface Props {
  filters: Filter[];
  hideAppliedFilters?: boolean;
}

export default function FiltersBar({ hideAppliedFilters, filters }: Props) {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let params: any = [];

    searchParams.forEach((value, key) => {
      const entryList = Object.entries(qs.parse(value)).map(([, value]) => ({
        //@ts-ignore
        ...value,
        attribute: key,
      }));
      if (entryList.some((elem) => elem.value)) {
        params = [...params, ...entryList];
      }
    });

    setSelectedOptions(params);
  }, [searchParams]);

  const onDeleteFilter = (_attribute: string) => {
    searchParams.delete(_attribute);
    setSearchParams(searchParams);
  };

  const onChangeFilter = ({
    _attribute,
    _value,
  }: {
    _attribute: string;
    _value: string | object | boolean;
  }) => {
    if (_value) searchParams.set(_attribute, _value as string);
    else searchParams.delete(_attribute);
    setSearchParams(searchParams);
  };

  const onRemoveOption = (option: {
    value: string;
    attribute: string;
    label: string;
    isDisbled?: string;
  }) => {
    const _filters: { value: string; label: string; attribute: string }[] =
      Object.entries(qs.parse(searchParams.get(option.attribute) as any)).map(
        ([, value]) => ({ ...(value as object) })
      ) as any;

    searchParams.set(
      option.attribute,
      qs.stringify([
        ..._filters.filter((elem) => elem.value + '' !== option.value + ''),
      ])
    );
    setSearchParams(searchParams);
  };

  const getFilterComponent = ({ _filter }: { _filter: Filter }) => {
    switch (_filter.type) {
      case FilterType.SEARCH_BAR:
        return (
          <div className='xl:pl-4 xl:items-center xl:w-48 xl:px-0 xl:mb-0  px-4 flex items-center mb-4'>
            <input
              type='text'
              name={_filter.attribute}
              id={_filter.attribute}
              className='focus:ring-primary focus:border-primary block w-full text-sm border-gray-300 bg-neutral-50  rounded-full'
              placeholder={_filter.label || 'Cerca...'}
              onChange={(e) =>
                onChangeFilter({
                  _value: e.target.value,
                  _attribute: _filter.attribute,
                })
              }
              value={(searchParams.get(_filter.attribute) as string) || ''}
            />
            {searchParams.get(_filter.attribute) && (
              <BackspaceIcon
                className='h-5 w-5 text-gray-500 -ml-8 cursor-pointer'
                aria-hidden='true'
                onClick={() => onDeleteFilter(_filter.attribute)}
              />
            )}
          </div>
        );
      case FilterType.CHECKBOX:
        return (
          <div className='relative flex xl:items-start border-gray-200 px-4 sm:py-6 md:py-6 lg:py-6 xl:py-0 items-center gap-3'>
            <div className='flex items-center h-5'>
              <input
                id={_filter.attribute}
                aria-describedby='active-description'
                name={_filter.attribute}
                type='checkbox'
                checked={
                  Boolean(searchParams.get(_filter.attribute) === 'true') ||
                  false
                }
                className='focus:ring-primary h-4 w-4 text-primary border-primary rounded-full '
                onChange={(e) =>
                  onChangeFilter({
                    _value: e.target.checked || '',
                    _attribute: _filter.attribute,
                  })
                }
              />
            </div>
            <label
              htmlFor={_filter.attribute}
              className=' text-sm font-normal text-gray-700'
            >
              {_filter.label}
            </label>
          </div>
        );
      case FilterType.MULTISELECT:
        return (
          <SelectFilter
            api={_filter.source}
            queryKey={_filter.key + ''}
            label={_filter.label}
            filterName={_filter.attribute}
            searchForAttributes={_filter.searchForAttributes}
            isSearchable={_filter.isSearchable}
            isDisabled={_filter.isDisabled}
            defaultValues={_filter.defaultValues}
            isMutualEsclusion={_filter.isMutualEsclusion}
          />
        );
      case FilterType.DATE:
        return (
          <DateFilter label={_filter.label} attributeName={_filter.attribute} />
        );
      case FilterType.RADIO:
        return (
          <div className='flex items-center'>
            {_filter.source.map((option: any, index: number) => (
              <div
                key={index}
                className='relative flex xl:items-start border-gray-200 px-4 sm:py-6 md:py-6 lg:py-6 xl:py-0 items-center gap-3'
              >
                <div className='flex items-center h-5'>
                  <input
                    type='radio'
                    id={`${_filter.attribute}-${index}`}
                    name={_filter.attribute}
                    value={option.value}
                    checked={
                      searchParams.get(_filter.attribute) === option.value
                    }
                    onChange={(e) =>
                      onChangeFilter({
                        _value: e.target.value,
                        _attribute: _filter.attribute,
                      })
                    }
                    className='focus:ring-primary h-4 w-4 text-primary border-primary rounded-full '
                  />
                </div>
                <label
                  htmlFor={`${_filter.attribute}-${index}`}
                  className=' text-sm font-normal text-gray-700 '
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className='bg-white'>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 flex z-40 xl:hidden'
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto'>
              <div className='px-4 flex items-center justify-between'>
                <h2 className='text-lg font-IBM font-medium text-primary'>
                  Filtri
                </h2>
                <button
                  type='button'
                  className='-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400'
                  onClick={() => setOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              {filters.map((_filter, idx) => (
                <React.Fragment key={`filter-${idx}`}>
                  {getFilterComponent({ _filter })}
                </React.Fragment>
              ))}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Filters desktop */}
      <section aria-labelledby='filter-heading'>
        <h2 id='filter-heading' className='sr-only'>
          Desktop filters
        </h2>

        <div className='relative z-10 bg-white pb-4'>
          <div className='w-full px-4 flex items-center justify-end py-2'>
            <button
              type='button'
              className='inline-block text-sm font-medium text-gray-700 hover:text-gray-900 xl:hidden'
              onClick={() => setOpen(true)}
            >
              Filtri
            </button>

            <div className='hidden xl:block pr-4'>
              <div className='flow-root'>
                <Popover.Group className='-mx-4 flex items-center divide-x divide-gray-200'>
                  {filters.map((_filter, idx) => (
                    <React.Fragment key={`filter-${idx}`}>
                      {getFilterComponent({ _filter })}
                    </React.Fragment>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Filtri applicati */}
        {!hideAppliedFilters && (
          <div className='bg-neutral-100'>
            <div className='py-3 px-4 xl:flex xl:items-center'>
              <h3 className='text-xs font-medium uppercase tracking-wide text-gray-500'>
                Filtri applicati
              </h3>

              <div
                aria-hidden='true'
                className='hidden w-px h-5 bg-gray-300 xl:block xl:ml-4'
              />

              <div className='mt-2 xl:mt-0 xl:ml-4'>
                <div className='-m-1 flex flex-wrap items-center'>
                  {selectedOptions.map((el, idx) => (
                    <span
                      key={`option-${idx}`}
                      className='m-1 inline-flex rounded-full border border-gray-200 items-center py-1.5 pl-3 pr-2 text-sm font-normal bg-white text-gray-900'
                    >
                      <span>{getOptionLabel(el)}</span>
                      <button
                        type='button'
                        className='flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500'
                        onClick={() => onRemoveOption(el)}
                        disabled={el.isDisabled === 'true'}
                      >
                        <span className='sr-only'>
                          Remove filter for {'activeFilter'}
                        </span>
                        <svg
                          className='h-2 w-2'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 8 8'
                        >
                          <path
                            strokeLinecap='round'
                            strokeWidth='1.5'
                            d='M1 1l6 6m0-6L1 7'
                          />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

const getOptionLabel = (option: SelectedOption) => {
  switch (option.type) {
    case 'DATE':
      return (
        <>
          <strong>Da </strong>
          {qs.parse(option.label).from} <strong>A </strong>
          {qs.parse(option.label).to}
        </>
      );
    default:
      return option.label;
  }
};
