import React, { useEffect, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { CourseLessonRequest } from '../../interfaces/lesson';
import SingleTeacherSelect from '../FetchSelect/SingleTeacherSelect';
import MemberTeacherSelect from '../FetchSelect/MemberTeacherSelect';
import InputPresentational from './InputPresentational';

interface RadioGroupTeacherProps {
  causale?: string;
  control?: any;
}
const RadioGroupTeacher: React.FC<RadioGroupTeacherProps> = () => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CourseLessonRequest>();

  const [showTeachersSelect, setShowTeachersSelect] = useState(true);
  const [showMemberSelect, setShowMemberSelect] = useState(false);
  const [
    isWaiverTeacher,
    isMemberTeacher,
    availableTeacher,
    waiverTeacher,
    teacher,
    lessonTemplate,
  ] = watch([
    'isWaiverTeacher',
    'isMemberTeacher',
    'availableTeacher',
    'waiverTeacher',
    'teacher',
    'lessonTemplate',
  ]);

  useEffect(() => {
    if (isWaiverTeacher) {
      setShowTeachersSelect(false);
      setShowMemberSelect(false);
      setValue('isMemberTeacher', false);
    } else if (isMemberTeacher) {
      setShowTeachersSelect(false);
      setShowMemberSelect(true);
      setValue('isWaiverTeacher', false);
    } else {
      setShowTeachersSelect(true);
      setShowMemberSelect(false);
      setValue('isWaiverTeacher', false);
      setValue('isMemberTeacher', false);
    }
  }, [isWaiverTeacher, isMemberTeacher]);

  return (
    <fieldset className='mt-4'>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 '>
        <div key='teacher1' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='regularTeacher'
              aria-describedby='description'
              name='teacherType'
              type='radio'
              className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
              checked={!isWaiverTeacher && !isMemberTeacher}
              onClick={() => {
                setValue('isWaiverTeacher', false);
                setValue('isMemberTeacher', false);
                setValue('teacher', null);
              }}
            />
          </div>
          <div className='ml-3 text-sm w-full'>
            <label
              htmlFor='regularTeacher'
              className='font-medium text-gray-700'
            >
              Scegli un docente dalla lista
            </label>
            {showTeachersSelect && (
              <SingleTeacherSelect
                availableTeacher={availableTeacher as number[]}
                label=''
                id={'teacher'}
                name={'teacher'}
                disabled={isWaiverTeacher || isMemberTeacher}
                control={control as Control<any>}
                error={{ ...errors.teacher, type: 'required' }}
                defaultValue={watch('teacher')}
                lessonTemplateId={lessonTemplate?.data?.id}
              />
            )}
          </div>
        </div>

        <div key='teacher2' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='memberTeacher'
              aria-describedby='description'
              name='teacherType'
              type='radio'
              className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
              checked={isMemberTeacher}
              onClick={() => {
                setValue('isWaiverTeacher', false);
                setValue('isMemberTeacher', true);
                setValue('teacher', null);
              }}
            />
          </div>
          <div className='ml-3 text-sm w-full'>
            <label
              htmlFor='memberTeacher'
              className='font-medium text-gray-700'
            >
              Socio in deroga
            </label>
            {showMemberSelect && (
              <MemberTeacherSelect
                label=''
                id={'teacher'}
                name={'teacher'}
                disabled={isWaiverTeacher || !isMemberTeacher}
                control={control as Control<any>}
                error={{ ...errors.teacher, type: 'required' }}
                defaultValue={watch('teacher')}
              />
            )}
          </div>
        </div>

        <div key='teacher3' className='relative flex items-start'>
          <div className='flex items-center h-5'>
            <input
              id='externalTeacher'
              aria-describedby='description'
              name='teacherType'
              type='radio'
              className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
              checked={isWaiverTeacher}
              onClick={() => {
                setValue('isWaiverTeacher', true);
              }}
            />
          </div>
          <div className='ml-3 text-sm w-full'>
            <label
              htmlFor='externalTeacher'
              className='font-medium text-gray-700'
            >
              Esterno non iscritto al portale
            </label>

            <div className='mt-1'>
              {!showTeachersSelect && !showMemberSelect && (
                <InputPresentational
                  id='waiverTeacher'
                  name='waiverTeacher'
                  type='text'
                  label=''
                  register={register}
                  defaultValue={waiverTeacher}
                  disabled={!isWaiverTeacher}
                  error={{ ...errors.waiverTeacher, type: 'required' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default RadioGroupTeacher;
