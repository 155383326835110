import React from 'react';
import { Attachment } from '../../../interfaces/reimbursment';
import BaseModal from '../BaseModal';
import InputPresentational from '../../Form/InputPresentational';
import PrimaryButton from '../../Buttons/PrimaryButton';
import ProfileSearchSelect from '../../FetchSelect/ProfileSearchSelect';
import { Profile } from '../../../interfaces/profile';
import { Control } from 'react-hook-form';

interface AdminBaseReimbursementFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  isEdit?: boolean;
  children?: React.ReactNode;
  // Form props
  register: any;
  control: Control<any>;
  errors: any;
  watch: any;
  setValue: any;
  isSubmitting: boolean;
  files: {
    existing: Attachment[];
    pending: File[];
  };
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLLabelElement>) => void;
  handleRemovePending: (index: number) => void;
}

const AdminBaseReimbursementFormModal: React.FC<
  AdminBaseReimbursementFormModalProps
> = ({
  isOpen,
  onClose,
  title,
  isEdit = false,
  children,
  // Form props
  register,
  control,
  errors,
  watch,
  setValue,
  isSubmitting,
  handleSubmit,
}) => {
  const note = watch('note') as string | undefined;

  const handleProfileSelect = (profile: Profile) => {
    setValue('module.personalInfo.firstName', profile.attributes.name);
    setValue('module.personalInfo.lastName', profile.attributes.surname);
    setValue('module.personalInfo.address', profile.attributes.address);
    setValue(
      'module.personalInfo.streetNumber',
      profile.attributes.streetNumber
    );
    setValue('module.personalInfo.city', profile.attributes.city);
    setValue(
      'module.personalInfo.province',
      profile.attributes.province?.data?.attributes?.name
    );
    setValue('module.personalInfo.zip', profile.attributes.zipcode);
    setValue(
      'module.personalInfo.phone',
      profile.attributes.mobilePhone || profile.attributes.altPhone
    );
    setValue(
      'module.personalInfo.email',
      profile.attributes.user?.data?.attributes?.email
    );
    setValue('module.personalInfo.fiscalCode', profile.attributes.fiscalCode);
  };

  return (
    <BaseModal hideBottom isOpen={isOpen} toggle={onClose} title={title}>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className='space-y-6'
      >
        {/* Personal Information */}
        <div className='bg-white p-6 rounded-lg space-y-4'>
          <h4 className='text-lg font-medium text-gray-900'>Dati Personali</h4>

          <div className='mb-4'>
            <ProfileSearchSelect
              name='profile'
              control={control}
              label='Cerca profilo'
              onProfileSelect={handleProfileSelect}
              error={errors.profile}
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <InputPresentational
              id='module.personalInfo.firstName'
              label='Nome *'
              name='module.personalInfo.firstName'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.firstName}
            />
            <InputPresentational
              id='module.personalInfo.lastName'
              label='Cognome *'
              name='module.personalInfo.lastName'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.lastName}
            />
            <InputPresentational
              id='module.personalInfo.address'
              label='Indirizzo'
              name='module.personalInfo.address'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.address}
            />
            <InputPresentational
              id='module.personalInfo.streetNumber'
              label='Civico'
              name='module.personalInfo.streetNumber'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.streetNumber}
            />
            <InputPresentational
              id='module.personalInfo.city'
              label='Città'
              name='module.personalInfo.city'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.city}
            />
            <InputPresentational
              id='module.personalInfo.province'
              label='Provincia'
              name='module.personalInfo.province'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.province}
            />
            <InputPresentational
              id='module.personalInfo.zip'
              label='CAP'
              name='module.personalInfo.zip'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.zip}
            />
            <InputPresentational
              id='module.personalInfo.phone'
              label='Telefono'
              name='module.personalInfo.phone'
              type='tel'
              register={register}
              error={errors.module?.personalInfo?.phone}
            />
            <InputPresentational
              id='module.personalInfo.email'
              label='Email'
              name='module.personalInfo.email'
              type='email'
              register={register}
              error={errors.module?.personalInfo?.email}
            />
            <InputPresentational
              id='module.personalInfo.fiscalCode'
              label='Codice Fiscale'
              name='module.personalInfo.fiscalCode'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.fiscalCode}
            />
            <InputPresentational
              id='module.personalInfo.iban'
              label='IBAN'
              name='module.personalInfo.iban'
              type='text'
              register={register}
              error={errors.module?.personalInfo?.iban}
            />
          </div>
        </div>

        {/* Child components (specific to lesson/course) */}
        {children}

        {/* Note */}
        <div className='bg-white p-6 rounded-lg space-y-4'>
          <h4 className='text-lg font-medium text-gray-900'>Note</h4>
          <textarea
            value={note}
            onChange={(e) => setValue('note', e.target.value)}
            rows={4}
            className='shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md'
          />
        </div>

        {/* Submit Button */}
        <div className='flex justify-end space-x-3'>
          <button
            type='button'
            onClick={onClose}
            className='px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50'
          >
            Annulla
          </button>
          <PrimaryButton type='submit' disabled={isSubmitting}>
            {isSubmitting
              ? 'Salvataggio...'
              : isEdit
              ? 'Salva modifiche'
              : 'Crea'}
          </PrimaryButton>
        </div>
      </form>
    </BaseModal>
  );
};

export default AdminBaseReimbursementFormModal;
