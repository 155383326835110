import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getSectionJobs } from '../api/section';
import { useAuth } from '../contexts/Auth';
import { CourseStatus } from '../interfaces/commons';
import { OnavPositionRole } from '../interfaces/onavPosition';
import { isDelegato } from '../libs/utils/auth';
import { checkProfile } from '../libs/utils/helpers';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import { SearchableSelect } from './Form/SearchableSelect';
import RegisterCourseSupervisorPaymentModal from './Modals/RegisterCourseSupervisorPaymentModal';
import RegisterCourseTreasurerPaymentModal from './Modals/RegisterCourseTreasurerPaymentModal';
import H4SpacingLetter from './Typography/H4SpacingLetter';

const FormDirezione = () => {
  const { register, getValues, watch, setValue, control } = useFormContext();
  const [{ profile, token }] = useAuth();

  const isCourseCanceled = useMemo(
    () => getValues('status') === CourseStatus.CANCELED,
    [getValues('status')]
  );
  const canDelegatoEdit =
    watch('canDelegateEdit') && !watch('disableSecondLevel');

  const sectionId = watch('section');

  const { data: sectionJobs, isLoading } = useQuery(
    ['sectionJobs', sectionId],
    () => getSectionJobs({ sectionId, token }),
    {
      enabled: !!sectionId,
    }
  );

  const treasurerOptions = useMemo(() => {
    if (!sectionJobs?.data) return [];
    return sectionJobs.data
      .filter(
        (job) =>
          job.attributes.onavPosition.data.attributes.role ===
            OnavPositionRole.TESORIERE ||
          job.attributes.onavPosition.data.attributes.role ===
            OnavPositionRole.TESORIERE_PRO_TEMPORE
      )
      .map((job) => ({
        value: job.attributes.profile.data.id,
        label: `${job.attributes.profile.data.attributes.name} ${job.attributes.profile.data.attributes.surname}`,
      }));
  }, [sectionJobs]);

  const supervisorOptions = useMemo(() => {
    if (!sectionJobs?.data) return [];
    return sectionJobs.data
      .filter(
        (job) =>
          job.attributes.profile.data &&
          job.attributes.onavPosition.data.attributes.role
      )
      .map((job) => {
        return {
          value: job.attributes.profile.data.id,
          label: `${job.attributes.profile.data.attributes.name} ${job.attributes.profile.data.attributes.surname}`,
        };
      });
  }, [sectionJobs]);

  const onChangeTreasurerAmount = (value: number) => {
    const limit = Number(watch('treasurerAmountLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('treasurerAmount', limit);
      toast.error("L'importo consuntivo non può superare l'importo preventivo");
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('treasurerAmount', value);
    }

    if (!isDelegato(profile)) setValue('treasurerAmount', value);
  };

  const onChangeSupervisorAmount = (value: number) => {
    const limit = Number(watch('supervisorAmountLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('supervisorAmount', limit);
      toast.error("L'importo consuntivo non può superare l'importo preventivo");
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('supervisorAmount', value);
    }

    if (!isDelegato(profile)) setValue('supervisorAmount', value);
  };

  return (
    <div className='pt-6 pb-8 grid grid-cols-1 md:grid-cols-2 gap-4 mt-6'>
      <div className='px-3 py-4 bg-neutral-100 rounded-md'>
        <H4SpacingLetter>Tesoriere</H4SpacingLetter>

        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <div className='xl:col-span-2'>
            {getValues('treasurerName') ? (
              <>
                <InputPresentational
                  id='treasurerName'
                  label='Nome e Cognome (Inserito manualmente)'
                  name='treasurerName'
                  type='text'
                  register={register}
                  disabled={true}
                />
                <div className='mt-4'>
                  <SearchableSelect
                    name='treasurer'
                    registerOptions={{
                      setValueAs(option) {
                        return option.value;
                      },
                    }}
                    label='Nome e Cognome (Seleziona da elenco)'
                    control={control}
                    options={treasurerOptions}
                    onInput={() => {}}
                    isLoading={isLoading}
                    disabled={isCourseCanceled}
                    placeholder='Seleziona tesoriere...'
                  />
                </div>
              </>
            ) : (
              <SearchableSelect
                name='treasurer'
                registerOptions={{
                  setValueAs(option) {
                    return option.value;
                  },
                }}
                label='Nome e Cognome'
                control={control}
                options={treasurerOptions}
                onInput={() => {}}
                isLoading={isLoading}
                disabled={isCourseCanceled}
                placeholder='Seleziona tesoriere...'
              />
            )}
          </div>

          <PriceInputPresentational
            id='treasurerAmount'
            label='Preventivo'
            name='treasurerAmount'
            type='number'
            disabled={
              isCourseCanceled || checkProfile(profile, canDelegatoEdit)
            }
            value={watch('treasurerAmount')}
            onChange={(e) => onChangeTreasurerAmount(Number(e.target.value))}
          />
        </div>
        {watch('treasurerAmountPaid') || watch('treasurerAmountPaid') === 0 ? (
          <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <InputPresentational
              id='treasurerPaymentDate'
              label='Data pagamento'
              name='treasurerPaymentDate'
              type='date'
              defaultValue={watch('treasurerPaymentDate')}
              disabled
            />
            <PriceInputPresentational
              id='treasurerAmount'
              label='Somma pagata'
              name='treasurerAmount'
              type='number'
              disabled
              defaultValue={watch('treasurerAmountPaid')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <RegisterCourseTreasurerPaymentModal course={getValues()} />
        </div>
      </div>

      <div className='px-3 py-4 bg-neutral-100 rounded-md'>
        <H4SpacingLetter>Responsabile</H4SpacingLetter>

        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <div className='xl:col-span-2'>
            {getValues('supervisorName') ? (
              <>
                <InputPresentational
                  id='supervisorName'
                  label='Nome e Cognome (Inserito manualmente)'
                  name='supervisorName'
                  type='text'
                  register={register}
                  disabled={true}
                />
                <div className='mt-4'>
                  <SearchableSelect
                    name='supervisor'
                    label='Nome e Cognome (Seleziona da elenco)'
                    control={control}
                    options={supervisorOptions}
                    registerOptions={{
                      setValueAs(option) {
                        return option.value;
                      },
                    }}
                    onInput={() => {}}
                    isLoading={isLoading}
                    disabled={isCourseCanceled}
                    placeholder='Seleziona responsabile...'
                  />
                </div>
              </>
            ) : (
              <SearchableSelect
                name='supervisor'
                label='Nome e Cognome'
                control={control}
                options={supervisorOptions}
                registerOptions={{
                  setValueAs(option) {
                    return option.value;
                  },
                }}
                onInput={() => {}}
                isLoading={isLoading}
                disabled={isCourseCanceled}
                placeholder='Seleziona responsabile...'
              />
            )}
          </div>

          <PriceInputPresentational
            id='supervisorAmount'
            label='Preventivo'
            name='supervisorAmount'
            type='number'
            disabled={
              isCourseCanceled || checkProfile(profile, canDelegatoEdit)
            }
            value={watch('supervisorAmount')}
            onChange={(e) => onChangeSupervisorAmount(Number(e.target.value))}
          />
        </div>
        {watch('supervisorAmountPaid') ||
        watch('supervisorAmountPaid') === 0 ? (
          <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <InputPresentational
              id='supervisorPaymentDate'
              label='Data pagamento'
              name='supervisorPaymentDate'
              type='date'
              defaultValue={watch('supervisorPaymentDate')}
              disabled
            />
            <PriceInputPresentational
              id='supervisorAmount'
              label='Somma pagata'
              name='supervisorAmount'
              type='number'
              disabled
              defaultValue={watch('supervisorAmountPaid')}
            />
          </div>
        ) : (
          <></>
        )}
        <div className='mt-6 grid grid-cols-1 xl:grid-cols-2 gap-4'>
          <RegisterCourseSupervisorPaymentModal course={getValues()} />
        </div>
      </div>
    </div>
  );
};

export default FormDirezione;
