import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import Select from 'react-select';

interface Props {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  description?: string;
  prefix?: string;
  suffix?: string;
  rows?: number;
  required?: boolean;
  register?: UseFormRegister<FieldValues>;
  registerOptions?: RegisterOptions;
  autoFocus?: boolean;
  error?: FieldError;
  disabled?: boolean;
  className?: string;
  onChange?: any;
  control: any;
  onInput: (e: string) => void;
  options: Option[];
  defaultValue?: number | string;
  isLoading?: boolean;
  value?: any;
}

export interface Option {
  value?: any;
  label?: string;
  isDisabled?: boolean;
}

export const SearchableSelect: React.FC<Props> = ({
  options,
  onInput,
  label,
  name,
  control,
  defaultValue,
  error,
  placeholder = 'Cerca...',
  isLoading,
  className,
  disabled,
  value,
  onChange: propsOnChange,
  registerOptions,
}) => {
  const [search, setSearch] = useState('');

  const getValue = (val: any) => {
    if (!val) return null;
    if (typeof val === 'object') return val;
    return options.find((opt) => opt.value === val) || null;
  };

  useEffect(() => {
    onInput(search);
  }, [search]);

  const filterOptions = (option: Option, input: string) => {
    if (input) {
      const inputWords = input.toLowerCase().split(' ');
      return inputWords.every((word) =>
        option.label?.toLowerCase().includes(word)
      );
    }
    return true;
  };

  useEffect(() => {
    if (!!defaultValue && options.length > 0)
      setSearch(options.find((opt) => opt.value === defaultValue)?.label || '');
  }, [defaultValue]);

  return (
    <div className={className}>
      <span className='block text-sm font-regular leading-5 text-gray-500'>
        {label}
      </span>
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              id={name}
              options={options}
              styles={selectStyle(error) as any}
              onInputChange={setSearch}
              onChange={(option) => {
                if (registerOptions?.setValueAs) {
                  const setValueAs = registerOptions.setValueAs(option);
                  onChange(setValueAs);
                  propsOnChange?.(option);
                } else {
                  onChange(option);
                  propsOnChange?.(option);
                }
              }}
              defaultValue={getValue(defaultValue)}
              placeholder={placeholder}
              value={getValue(value)}
              inputValue={search}
              isLoading={isLoading}
              isDisabled={disabled}
              filterOption={filterOptions}
            />
          );
        }}
      />
      {error && error.message && (
        <p className='mt-2 text-sm text-red-500'>{error.message}</p>
      )}
    </div>
  );
};

export const selectStyle = (error?: FieldError) => {
  return {
    control: (styles: any, state: any) => {
      state.theme.colors = {
        primary: '#40bca4',
        primary75: '#40bca4',
        primary50: '#B2D4FF',
        primary25: '#DEEBFF',
        danger: '#DE350B',
        dangerLight: '#FFBDAD',
        neutral0: 'hsl(0, 0%, 100%)',
        neutral5: 'hsl(0, 0%, 95%)',
        neutral10: 'hsl(0, 0%, 90%)',
        neutral20: 'hsl(0, 0%, 80%)',
        neutral30: 'hsl(0, 0%, 70%)',
        neutral40: 'hsl(0, 0%, 60%)',
        neutral50: 'hsl(0, 0%, 50%)',
        neutral60: 'hsl(0, 0%, 40%)',
        neutral70: 'hsl(0, 0%, 30%)',
        neutral80: 'hsl(0, 0%, 20%)',
        neutral90: 'hsl(0, 0%, 10%)',
      };
      return {
        ...styles,
        color: 'black',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '6px',
        borderColor:
          (error?.message && 'rgba(244, 63, 94, .5)') ||
          (state.isFocused && 'rgb(110,0,40)') ||
          'rgb(209 213 219)',
        boxShadow:
          '0 2px 3px 0 rgba(0, 0, 0, 0.02), 0 1px 1px 0 rgba(0, 0, 0, 0.03)',
        '&:hover': {
          borderColor:
            (error?.message && 'rgba(248, 60, 92, .5)') ||
            (!state.isFocused && 'transparent') ||
            '#6E0028',
        },
      };
    },
    option: (
      styles: any,
      {
        isDisabled,
        isFocused,
        isSelected,
      }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }
    ) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isFocused ? '#6E0028' : null,
        color: isDisabled ? 'gray' : isFocused ? 'white' : 'black',
        fontWeight: isSelected ? 'bold' : null,
      };
    },
    menu: (styles: object) => {
      return {
        ...styles,
        border: 0,
        borderColor: 'transparent',
        'z-index': 100000,
      };
    },
    valueContainer: (styles: object) => {
      return {
        ...styles,
        border: 0,
      };
    },
    indicatorSeparator: (styles: object) => {
      return { ...styles };
    },
    indicatorContainer: (styles: object) => {
      return {
        ...styles,
        fill: 'red',
      };
    },
    dropdownIndicator: (styles: object) => {
      return {
        ...styles,
        // color: 'blue',
        fill: 'none',
      };
    },
    input: (styles: object) => {
      return {
        ...styles,
        // color: 'red',
        // backgroundColor: 'red',
        // fill: 'red',
        // borderStyle: 'dotted',
        // border: 1,
        // borderColor: 'red',
        height: '32px',
      };
    },
    group: (base: object) => {
      return {
        ...base,
        borderRadius: '3px',
        color: 'lightgray',
        fontWeight: '100',
      };
    },
    multiValue: (base: object) => ({
      ...base,
      backgroundColor: 'rgb(110, 0, 40)',
      color: 'gray',
    }),
    multiValueLabel: (base: object) => ({
      ...base,
      color: 'white',
      fontWeight: 'lighter',
    }),
  };
};
