import { StyleSheet, Font } from '@react-pdf/renderer';

// Register fonts
Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: '/fonts/Ubuntu-Regular.ttf',
      fontWeight: 'normal',
    },
    {
      src: '/fonts/Ubuntu-Medium.ttf',
      fontWeight: 'medium',
    },
    {
      src: '/fonts/Ubuntu-Bold.ttf',
      fontWeight: 'bold',
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Ubuntu',
    fontSize: 10,
    padding: 30,
  },
  section: {
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    color: '#4F46E5', // primary color
  },
  meta: {
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB', // gray-200
    paddingBottom: 10,
  },
  metaRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'medium',
    marginBottom: 10,
    color: '#111827', // gray-900
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  col: {
    flex: 1,
    paddingRight: 10,
  },
  col2: {
    flex: 2,
    paddingRight: 10,
  },
  label: {
    fontSize: 9,
    color: '#6B7280', // gray-500
    marginBottom: 2,
  },
  value: {
    fontSize: 10,
    color: '#111827', // gray-900
  },
  expenseItem: {
    backgroundColor: '#F9FAFB', // gray-50
    padding: 10,
    borderRadius: 4,
    marginBottom: 8,
  },
  summary: {
    backgroundColor: '#F9FAFB', // gray-50
    padding: 15,
    borderRadius: 4,
    marginBottom: 15,
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  signature: {
    marginTop: 50,
    borderTopWidth: 1,
    borderTopColor: '#E5E7EB', // gray-200
    paddingTop: 10,
  },
  // Status badges
  badge: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 9999,
    fontSize: 9,
    alignSelf: 'flex-start',
  },
  badgeGreen: {
    backgroundColor: '#D1FAE5', // green-100
    color: '#065F46', // green-800
  },
  badgeRed: {
    backgroundColor: '#FEE2E2', // red-100
    color: '#991B1B', // red-800
  },
  badgeYellow: {
    backgroundColor: '#FEF3C7', // yellow-100
    color: '#92400E', // yellow-800
  },
  badgeGray: {
    backgroundColor: '#F3F4F6', // gray-100
    color: '#1F2937', // gray-800
  },
  badgeBlue: {
    backgroundColor: '#DBEAFE', // blue-100
    color: '#1E40AF', // blue-800
  },
});
