import React, { useEffect, useState } from 'react';
import { FieldError, UseControllerProps, useWatch } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { getProfiles } from '../../api/profile';
import { useAuth } from '../../contexts/Auth';
import { useDebounce } from 'use-debounce';
import { SearchableSelect } from '../Form/SearchableSelect';
import { match } from 'ts-pattern';
import { getQuerySearchFilters } from '../../libs/utils/profile';
import { Profile } from '../../interfaces/profile';

type ProfileSearchSelectProps = {
  id?: string;
  label: string;
  disabled?: boolean;
  error?: FieldError;
  onProfileSelect?: (profile: Profile) => void;
} & UseControllerProps;

const ProfileSearchSelect: React.FC<ProfileSearchSelectProps> = ({
  control,
  name,
  label,
  disabled = false,
  error,
  defaultValue,
  onProfileSelect,
}) => {
  const [{ token }] = useAuth();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const value = useWatch({ control, name }) as number;

  useEffect(() => {
    query.refetch();
  }, [debouncedSearch, value]);

  const query = useQuery(
    ['profiles-select'],
    () => {
      const filters = getQuerySearchFilters(search);
      const q = {
        filters: {
          $or: [
            ...filters,
            {
              id: value,
            },
          ],
        },
        pagination: {
          pageSize: 50,
        },
        forceListing: true,
      };
      return getProfiles({
        token,
        query: q,
      });
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const options = React.useMemo(() => {
    if (!query?.data) return [];
    return query.data.data.map((profile: Profile) => ({
      value: profile.id,
      label: `${profile.attributes.name} ${profile.attributes.surname}`,
      profile,
    }));
  }, [query]);

  const handleChange = (value: (typeof options)[number]) => {
    onProfileSelect?.(value.profile);
  };

  return (
    <SearchableSelect
      label={label}
      name={name}
      /*       defaultValue={defaultValue} */
      disabled={disabled}
      control={control}
      error={error}
      onInput={setSearch}
      isLoading={query.isFetching}
      onChange={handleChange}
      options={options}
      registerOptions={{
        setValueAs: (value) => {
          return value.value;
        },
      }}
    />
  );
};

export default ProfileSearchSelect;
