import moment from 'moment';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { UserSectionsSelect } from '../../components/FetchSelect/SectionsSelect';
import InputPresentational from '../../components/Form/InputPresentational';
import {
  StaticInputLabel,
  StaticInputValue,
} from '../../components/Form/StaticInputPresentational';
import FormLoading from '../../components/Layout/Loading/FormLoading';
import H3Styled from '../../components/Typography/H3Styled';
import { useAuth } from '../../contexts/Auth';
import {
  isDelegato,
  isDelegatoSezione,
  isSegreteriaNazionale,
} from '../../libs/utils/auth';
import classNames from '../../libs/utils/classNames';
import { useComparisonStatistics } from './hooks';

const DateFormatter = new Intl.DateTimeFormat('it-IT', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export function ComparisonStatistic() {
  const [{ profile }] = useAuth();
  const [sectionId, setSectionId] = useState<string | undefined>(
    isDelegato(profile) ? profile?.section.data?.id.toString() : 'ALL_SECTIONS'
  );
  const [date, setDate] = useState<string | undefined>(
    new Date().toISOString()
  );
  const statisticsQuery = useComparisonStatistics({
    sectionId: sectionId === 'ALL_SECTIONS' ? undefined : sectionId,
    date,
  });

  const yearsDiff = moment().year() - moment(date).year();

  return (
    <div className='bg-neutral-50 py-8 px-4 rounded-lg space-y-8'>
      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <H3Styled>Statistiche comparative</H3Styled>
        </div>
        <div className='flex flex-row items-center flex-wrap gap-2 sm:flex-nowrap sm:max-w-lg'>
          <InputPresentational
            register={() => ({
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setDate(event.target.value),
            })}
            defaultValue={moment(date).format('YYYY-MM-DD')}
            id='end-date'
            label='Data di riferimento'
            name='end-date'
            type='date'
          />
          {!isDelegatoSezione(profile) && (
            <UserSectionsSelect
              options={
                isSegreteriaNazionale(profile)
                  ? [
                      {
                        label: 'Tutte le sezioni',
                        value: 'ALL_SECTIONS',
                      },
                    ]
                  : undefined
              }
              defaultValue={sectionId}
              onChangeValue={(value) => setSectionId(value?.value.toString())}
            />
          )}
        </div>
      </div>

      {match(statisticsQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={12} />
        ))
        .with({ status: 'error' }, () => (
          <div>Errore nel recupero delle statistiche comparative...</div>
        ))
        .with({ status: 'success' }, ({ data: statisticsData }) => {
          return (
            <>
              <div className='space-y-4 overflow-x-auto -mx-4'>
                <div
                  className={classNames(
                    'grid   gap-4   place-items-start [&>*]:px-4',
                    'grid-cols-[0.5fr_1fr_1fr_1fr_.5fr_0.5fr]'
                  )}
                >
                  <StaticInputLabel>Tipologia</StaticInputLabel>

                  {statisticsData.years.map((year) => (
                    <StaticInputLabel key={year.year}>
                      <span className='hidden md:inline'>
                        Tessere {year.year} acquisite al{' '}
                        {DateFormatter.format(year.until)}
                      </span>
                      <span className='md:hidden'>{year.year}</span>
                    </StaticInputLabel>
                  ))}

                  {statisticsData.changes.map((change) => (
                    <StaticInputLabel key={change.compareYear}>
                      {change.referenceYear} / {change.compareYear}
                    </StaticInputLabel>
                  ))}

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Sottoscrizioni totali</StaticInputLabel>
                  {statisticsData.subscriptions.total.values.map((value) => (
                    <StaticInputValue key={value.year}>
                      {value.value}
                    </StaticInputValue>
                  ))}
                  {statisticsData.subscriptions.total.changes.map((change) => (
                    <StaticInputValue key={change.compareYear}>
                      {change.change.percentage}
                    </StaticInputValue>
                  ))}

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Rinnovi</StaticInputLabel>
                  {statisticsData.subscriptions.renewals.values.map((value) => (
                    <StaticInputValue key={value.year}>
                      {value.value}
                    </StaticInputValue>
                  ))}
                  {statisticsData.subscriptions.renewals.changes.map(
                    (change) => (
                      <StaticInputValue key={change.compareYear}>
                        {change.change.percentage}
                      </StaticInputValue>
                    )
                  )}

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Nuove sottoscrizioni</StaticInputLabel>
                  {statisticsData.subscriptions.newSubs.values.map((value) => (
                    <StaticInputValue key={value.year}>
                      {value.value}
                    </StaticInputValue>
                  ))}
                  {statisticsData.subscriptions.newSubs.changes.map(
                    (change) => (
                      <StaticInputValue key={change.compareYear}>
                        {change.change.percentage}
                      </StaticInputValue>
                    )
                  )}

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Riattivazioni</StaticInputLabel>
                  {statisticsData.subscriptions.reenrollments.values.map(
                    (value) => (
                      <StaticInputValue key={value.year}>
                        {value.value}
                      </StaticInputValue>
                    )
                  )}
                  {statisticsData.subscriptions.reenrollments.changes.map(
                    (change) => (
                      <StaticInputValue key={change.compareYear}>
                        {change.change.percentage}
                      </StaticInputValue>
                    )
                  )}

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Junior</StaticInputLabel>
                  {statisticsData.subscriptions.juniors.values.map((value) => (
                    <StaticInputValue key={value.year}>
                      {value.value}
                    </StaticInputValue>
                  ))}
                  {statisticsData.subscriptions.juniors.changes.map(
                    (change) => (
                      <StaticInputValue key={change.compareYear}>
                        {change.change.percentage}
                      </StaticInputValue>
                    )
                  )}
                </div>
              </div>
            </>
          );
        })
        .exhaustive()}
    </div>
  );
}
