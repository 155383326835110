import React, { useEffect } from 'react';
import { Profile } from '../../../../interfaces/profile';
import { useFormContext } from 'react-hook-form';
import FormDatiPrincipaliUtente from '../../../../components/FormDatiPrincipaliUtenti';

const ModificaUtenteAnagrafica = ({ userInfo }: { userInfo: Profile }) => {
  const { watch, setValue } = useFormContext();

  // Logica per cambiare le select di Nazione, regione e provincia

  useEffect(() => {
    //popola la select della provincia
    if (watch('region') === userInfo.attributes.region.data?.id) return;
    //resetta la provincia quando cambio regione
    setValue('province', null);
  }, [watch('region')]);

  useEffect(() => {
    if (watch('nation') !== 1) {
      setValue('province', null, { shouldDirty: true });
      setValue('region', null, { shouldDirty: true });
    }
  }, [watch('nation')]);

  useEffect(() => {
    setValue('homeAddress.province', null);
  }, [watch('homeAddress.region')]);

  useEffect(() => {
    if (watch('homeAddress.nation') !== 1) {
      setValue('homeAddress.province', null, { shouldDirty: true });
      setValue('homeAddress.region', null, { shouldDirty: true });
    }
  }, [watch('homeAddress.nation')]);

  return (
    <div className=''>
      <FormDatiPrincipaliUtente infoUser={userInfo} />
    </div>
  );
};

export default ModificaUtenteAnagrafica;
