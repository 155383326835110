import { useEffect, useMemo, useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import BaseModal from './BaseModal';
import { useQuery, UseQueryResult } from 'react-query';
import { getProfiles } from '../../api/profile';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/Auth';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { ProductType } from '../../interfaces/orders';
import { ParticipantsResponse } from '../../interfaces/participants';
import { Option, SearchableSelect } from '../Form/SearchableSelect';
import { EventGuest } from '../../interfaces/events';
import ExtraParticipantSection from '../ExtraParticipantSection';
import { ProfileListResponse } from '../../interfaces/profile';
import { EventParticipation } from '../../interfaces/event-participants';
import { BadgeStatus } from '../../interfaces/badge';
import { getQuerySearchFilters } from '../../libs/utils/profile';
import { RadioGroup } from '@headlessui/react';
import InputPresentational from '../Form/InputPresentational';
import { ValidationErrorMessages } from '../../validators/errors';

export interface EventParticipantForm {
  profile?: Option;
  guests: EventGuest[];
  type: 'FREE' | 'PREMIUM';
  firstName: string;
  lastName: string;
}

type ParticipantType = {
  type: 'PREMIUM' | 'FREE';
  name: string;
  description: string;
};

const PARTICIPANTS_TYPE: ParticipantType[] = [
  {
    type: 'PREMIUM',
    name: 'Pagante',
    description:
      'Solo per gli utenti ONAV. Saranno gestite le ricevute, ordini, pagamenti ecc.',
  },
  {
    type: 'FREE',
    name: 'Gratis',
    description:
      'Per chi non ha un profilo ONAV. Non avrà ricevute, ordini o pagamenti.',
  },
];

interface Props {
  onConfirm?: (data: EventParticipantForm) => void;
  participantsQuery?: UseQueryResult<
    {
      data: ParticipantsResponse[] | EventParticipation[];
    },
    unknown
  >;
  disabled?: boolean;
  entityDate: string;
  isLoading?: boolean;
}

const AddEventParticipantsModal: React.FC<Props> = ({
  onConfirm,
  participantsQuery,
  disabled,
  entityDate,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [participantsIds, setParticipantsIds] = useState<number[]>([]);
  const [step, setStep] = useState<number>(1);
  const [selected, setSelected] = useState(PARTICIPANTS_TYPE[0]);

  const [{ token }] = useAuth();
  const [debouncedSearch] = useDebounce(search, 500);

  const profilesQuery = useQuery(
    ['getProfileListForParticipations', participantsIds],
    () => {
      const courseDate = new Date(entityDate);
      const entityYear = courseDate.getFullYear();
      let badgeYears: number | number[] = entityYear;

      if (courseDate.getMonth() === 11 && courseDate.getDate() >= 1) {
        badgeYears = [entityYear, entityYear + 1];
      }

      if (courseDate.getMonth() === 0 && courseDate.getDate() <= 31) {
        badgeYears = [entityYear - 1, entityYear];
      }

      return getProfiles({
        token,
        query: {
          filters: {
            $and: getQuerySearchFilters(search),
            id: {
              $notIn: participantsIds,
            },
            badgeYear: badgeYears,
          },
          populate: ['orders.payment', 'orders.cardHistory'],
          pagination: {
            pageSize: 50,
          },
        },
      });
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    setParticipantsIds(
      participantsQuery?.data?.data?.map(
        (elem) => elem.attributes.profile?.data?.id as number
      ) || []
    );
  }, [participantsQuery?.data?.data]);

  const participantForm = useForm<EventParticipantForm>({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(
      Yup.object().shape({
        profile: Yup.object().when('type', {
          is: (status: 'FREE' | 'PREMIUM') => status === 'PREMIUM',
          then: Yup.object()
            .shape({
              value: Yup.number().required(ValidationErrorMessages.REQUIRED),
              label: Yup.string().optional(),
            })
            .required(ValidationErrorMessages.REQUIRED),
          otherwise: Yup.object().optional().nullable(),
        }),
        firstName: Yup.string().when('type', {
          is: (status: 'FREE' | 'PREMIUM') => status === 'FREE',
          then: Yup.string()
            .required(ValidationErrorMessages.REQUIRED)
            .typeError(ValidationErrorMessages.REQUIRED),
          otherwise: Yup.string().optional().nullable(),
        }),
        lastName: Yup.string().when('type', {
          is: (status: 'FREE' | 'PREMIUM') => status === 'FREE',
          then: Yup.string()
            .required(ValidationErrorMessages.REQUIRED)
            .typeError(ValidationErrorMessages.REQUIRED),
          otherwise: Yup.string().optional().nullable(),
        }),
        guests: Yup.array()
          .of(
            Yup.object().shape({
              firstName: Yup.string()
                .min(1, ValidationErrorMessages.REQUIRED)
                .required(ValidationErrorMessages.REQUIRED),
              lastName: Yup.string()
                .min(1, ValidationErrorMessages.REQUIRED)
                .required(ValidationErrorMessages.REQUIRED),
            })
          )
          .optional(),
      })
    ),
    defaultValues: {
      type: 'PREMIUM',
    },
  });

  const { control, handleSubmit, reset, formState, setValue, register } =
    participantForm;

  const { errors } = formState;

  const toggleModal = () => {
    setIsOpen((v) => !v);
    setStep(1);
    setSelected(PARTICIPANTS_TYPE[0]);
    reset({ type: 'PREMIUM' });
  };

  const onSubmit = (values: EventParticipantForm) => {
    onConfirm && onConfirm(values);
  };

  useEffect(() => {
    profilesQuery.refetch();
  }, [debouncedSearch]);

  function formatOptions(query: UseQueryResult<ProfileListResponse>) {
    if (query.isSuccess) {
      const options = query.data.data.map((profile) => ({
        label: `${profile.attributes.name} ${profile.attributes.surname}`,
        value: profile.id,
        isDisabled: profile.attributes.badgeStatus !== BadgeStatus.VALID,
      }));

      return options;
    } else return [];
  }

  const [hadSubmitted, setHadSubmitted] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setHadSubmitted(true);
    }

    if (hadSubmitted && !isLoading) {
      toggleModal();
      setHadSubmitted(false);
    }
  }, [isLoading, hadSubmitted]);

  const handleAddParticipant = () => {
    if (step === 1) return setStep(2);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <UnderlineButton disabled={disabled} onClick={() => setIsOpen(true)}>
        <PlusIcon className='w-4 h-4' /> Aggiungi partecipante
      </UnderlineButton>

      <BaseModal
        title={'Aggiungi partecipante'}
        isOpen={isOpen}
        toggle={toggleModal}
        className={'mx-auto md:w-[500px] w-11/12'}
        hideBottom
      >
        <form className='' onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && (
            <div className='w-full px-4 min-h-[360px]'>
              <div className='mx-auto w-full max-w-md'>
                <RadioGroup
                  value={selected}
                  onChange={(_participantType: any) => {
                    setValue('type', _participantType.type);
                    setSelected(_participantType);
                  }}
                >
                  <RadioGroup.Label className='sr-only'>
                    Server size
                  </RadioGroup.Label>
                  <div className='space-y-2 grid-cols-2 grid grid-flow-col space-x-4'>
                    {PARTICIPANTS_TYPE.map((_mode) => (
                      <RadioGroup.Option
                        key={_mode.name}
                        value={_mode}
                        className={({ active, checked }) =>
                          `${
                            active
                              ? 'bg-primary/5 ring-2 ring-primary/60 ring-offset-2 ring-offset-primary/75'
                              : ''
                          }
                  ${checked ? 'border-primary/75 border-2' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className='flex w-full items-center justify-between'>
                              <div className='flex items-center'>
                                <div className='text-sm'>
                                  <RadioGroup.Label
                                    as='p'
                                    className={`font-medium  ${'text-gray-900'}`}
                                  >
                                    {_mode.name}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as='span'
                                    className={`inline ${'text-gray-500'}`}
                                  >
                                    <span>{_mode.description}</span>
                                  </RadioGroup.Description>
                                </div>
                              </div>
                              {checked && (
                                <div className='shrink-0 text-white'>
                                  <CheckIcon className='h-6 w-6 bg-primary rounded-full' />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className='min-h-[360px]'>
              {selected.type === 'PREMIUM' && (
                <>
                  <SearchableSelect
                    onInput={setSearch}
                    options={formatOptions(profilesQuery)}
                    name='profile'
                    placeholder={'Cerca...'}
                    control={control}
                    error={errors.profile?.value as any}
                    isLoading={profilesQuery.isFetching}
                  />
                  <ExtraParticipantSection participantForm={participantForm} />
                </>
              )}

              {selected.type === 'FREE' && (
                <>
                  <InputPresentational
                    name='firstName'
                    label='Nome'
                    type='text'
                    error={errors.firstName}
                    register={register}
                    className='p-1 my-2'
                  />
                  <InputPresentational
                    name='lastName'
                    label='Cognome'
                    type='text'
                    className='p-1 my-2'
                    register={register}
                    error={errors.lastName}
                  />
                </>
              )}
            </div>
          )}

          <div className='flex items-center justify-end gap-4'>
            <PrimaryButton
              onClick={() => {
                if (step === 1) return toggleModal();
                setStep((v) => v - 1);
              }}
              textSmall
            >
              {step === 1 ? 'Annulla' : 'Indietro'}
            </PrimaryButton>
            <PrimaryButton
              isLoading={isLoading}
              disabled={isLoading || !selected}
              type='button'
              onClick={handleAddParticipant}
              small
            >
              {step === 1 ? 'Avanti' : 'Conferma'}
            </PrimaryButton>
          </div>
        </form>
      </BaseModal>
    </>
  );
};

export default AddEventParticipantsModal;
