import React, { useState } from 'react';
import { FieldError, UseControllerProps } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getProfiles } from '../../api/profile';
import { useAuth } from '../../contexts/Auth';
import { useDebounce } from 'use-debounce';
import { SearchableSelect } from '../Form/SearchableSelect';
import { match } from 'ts-pattern';
import { getQuerySearchFilters } from '../../libs/utils/profile';

type MemberTeacherSelectProps = {
  id: string;
  label: string;
  disabled?: boolean;
  error?: FieldError;
} & UseControllerProps;

const MemberTeacherSelect: React.FC<MemberTeacherSelectProps> = ({
  control,
  name,
  label,
  disabled = false,
  error,
  defaultValue,
}) => {
  const [{ token }] = useAuth();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);

  const query = useQuery(['member-teacher-select', debouncedSearch], () => {
    const filters = getQuerySearchFilters(debouncedSearch);
    return getProfiles({
      token,
      query: {
        filters: {
          $or: [
            ...filters,
            {
              id:
                typeof defaultValue === 'number'
                  ? defaultValue
                  : defaultValue?.value,
            },
          ],
        },
        pagination: {
          pageSize: 500,
        },
        forceListing: true,
      },
    });
  });

  return (
    <SearchableSelect
      label={label}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      control={control}
      error={error}
      onInput={setSearch}
      isLoading={query.isFetching}
      options={match(query)
        .with(
          { status: 'error' },
          { status: 'idle' },
          { status: 'loading' },
          () => []
        )
        .with({ status: 'success' }, (query) => {
          const options =
            query.data?.data.map((profile) => ({
              label: profile.attributes.name + ' ' + profile.attributes.surname,
              value: profile.id,
            })) || [];

          return options;
        })
        .exhaustive()}
    />
  );
};

export default MemberTeacherSelect;
