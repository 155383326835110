import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Disclosure, Popover, Transition } from '@headlessui/react';
import classNames from '../../../libs/utils/classNames';
import { useSearchParams } from 'react-router-dom';
import PrimaryButtonFull from '../../Buttons/PrimaryButtonFull';
import { useWindowSize } from '../../../libs/utils/helpers';
import QueryString from 'qs';
import { useDebounce } from 'use-debounce';

interface Props {
  attributeName: string;
  label?: string;
}

const DateFilter: React.FC<Props> = ({ attributeName, label }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [windowWidth] = useWindowSize();
  const [date, setDate] = useState<{
    from?: string | undefined;
    to?: string | undefined;
  }>({});

  useEffect(() => {
    if (searchParams.get(attributeName) === '') {
      setDate({});
      searchParams.delete(attributeName);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onSearchDate = () => {
    if (date.from || date.to) {
      searchParams.set(
        attributeName,
        QueryString.stringify([
          {
            label: QueryString.stringify(date),
            value: attributeName,
            type: 'DATE',
          },
        ])
      );
      setSearchParams(searchParams);
    }
  };

  // setto i valori iniziali
  useEffect(() => {
    const _date = QueryString.parse(
      Object.entries(
        QueryString.parse(searchParams.get(attributeName) + '')
      ).map(([, value]) => value as any)[0]?.label
    );

    if (_date.from || _date.to)
      setDate({ from: _date.from + '', to: _date.to + '' });
  }, []);

  const List = () => {
    return (
      <>
        <div className='w-full'>
          <label
            htmlFor='date-from'
            className='text-sm text-gray-500 flex justify-between'
          >
            <div>Data da </div>
          </label>
          <div className='mt-1 flex items-center'>
            <input
              id='data-from'
              defaultValue={date.from}
              type='date'
              onChange={(e) =>
                setDate((oldValues) => ({ ...oldValues, from: e.target.value }))
              }
              name='data-from'
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
            />
          </div>
        </div>

        <div className='w-full pb-4'>
          <label
            htmlFor='date-to'
            className='text-sm text-gray-500 flex justify-between'
          >
            <div>Data a </div>
          </label>
          <div className='mt-1 flex items-center'>
            <input
              id='data-to'
              defaultValue={date.to}
              type='date'
              onChange={(e) =>
                setDate((oldValues) => ({ ...oldValues, to: e.target.value }))
              }
              name='data-to'
              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-0 focus:border-gray-500 text-gray-800 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
            />
          </div>
        </div>

        <PrimaryButtonFull children={'Cerca per data'} onClick={onSearchDate} />
      </>
    );
  };

  return windowWidth < 1280 ? (
    <Disclosure
      as='div'
      key='select-date'
      className='border-t border-gray-200 px-4 py-6 pr-1'
    >
      {({ open }) => (
        <>
          <h3 className='-mx-2 -my-3 flow-root'>
            <Disclosure.Button className='px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400'>
              <span className='font-normal text-gray-800'>
                {label ?? 'Data'}
              </span>
              <span className='ml-6 flex items-center'>
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180' : 'rotate-0',
                    'h-5 w-5 transform'
                  )}
                  aria-hidden='true'
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className='pt-6'>
            <div className='w-full space-y-6 pr-3'>{List()}</div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Popover key='sections' className='px-4 relative inline-block text-left'>
      <Popover.Button className='group inline-flex justify-center text-sm font-normal text-gray-700 hover:text-gray-800'>
        <span>{label ?? 'Data'}</span>

        <ChevronDownIcon
          className='flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500'
          aria-hidden='true'
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Popover.Panel className='origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='w-56 space-y-6 '>{List()}</div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default DateFilter;
