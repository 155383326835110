import {
  DocumentTextIcon,
  PaperClipIcon,
  PencilIcon,
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  updateAdminCourseReimbursement,
  updateReimbursement,
} from '../../../api/reimbursement';
import BaseModal from '../../../components/Modals/BaseModal';
import AdminCourseReimbursementFormModal from '../../../components/Modals/ReimbursmentFormModal/AdminCourseReimbursementFormModal';
import { prepareReimbursementFormData } from '../../../components/Modals/ReimbursmentFormModal/utils';
import { useAuth } from '../../../contexts/Auth';
import {
  AdminCourseReimbursementFormData,
  CourseReimbursementModule,
  Reimbursement,
  ReimbursementAttributes,
  ReimbursementStatus,
} from '../../../interfaces/reimbursment';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import PDFExportButton from '../../../components/PDFExport/PDFExportButton';
import CourseReimbursementPDF from '../../../components/PDFExport/CourseReimbursementPDF';

interface CourseReimbursementRowProps {
  reimbursement: Reimbursement;
  searchWords: string[];
}

const CourseReimbursementRow: React.FC<CourseReimbursementRowProps> = ({
  reimbursement,
  searchWords,
}) => {
  const [{ token }] = useAuth();
  const queryClient = useQueryClient();
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [note, setNote] = useState(reimbursement.attributes.note || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const editMutation = useMutation(
    (data: FormData) =>
      updateAdminCourseReimbursement(reimbursement.id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('course-reimbursements');
        toast.success('Rimborso aggiornato con successo');
        setIsEditModalOpen(false);
      },
      onError: () => {
        toast.error("Errore durante l'aggiornamento del rimborso");
      },
    }
  );

  const handleEdit = async (data: ReimbursementAttributes, files: File[]) => {
    data.module.payment.amount = data.totalCompensation;
    const formData = prepareReimbursementFormData(data, files);
    await editMutation.mutateAsync(formData);
  };

  const handleStatusChange = async (newStatus: ReimbursementStatus) => {
    try {
      await updateReimbursement({
        id: reimbursement.id,
        data: {
          status: newStatus,
        },
        token,
      });
      queryClient.invalidateQueries('course-reimbursements');
      toast.success('Stato aggiornato con successo');
    } catch (error) {
      toast.error("Errore durante l'aggiornamento dello stato");
    }
  };

  const handleNoteSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateReimbursement({
        id: reimbursement.id,
        data: {
          note,
          status: ReimbursementStatus.WAITING_COMPLETION,
        },
        token,
      });
      queryClient.invalidateQueries('course-reimbursements');
      toast.success('Nota aggiornata con successo');
      setIsNoteModalOpen(false);
    } catch (error) {
      toast.error("Errore durante l'aggiornamento della nota");
    }
    setIsSubmitting(false);
  };

  const renderDetailsContent = () => {
    const courseModule = reimbursement.attributes
      .module as DeepPartial<CourseReimbursementModule>;
    if (!courseModule) return null;

    const course = getCourse();

    return (
      <div className='space-y-8'>
        <div>
          <div className='flex flex-row justify-between items-center'>
            <h4 className='text-lg font-medium text-gray-900 mb-4 '>
              Dettagli Corso
            </h4>
            {paymentDate && (
              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Pagato
              </span>
            )}
            {!paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.WAITING_COMPLETION && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                  Rendicontazione da compilare
                </span>
              )}
            {!paymentDate &&
              (reimbursement.attributes.status ===
                ReimbursementStatus.IN_REVIEW ||
                reimbursement.attributes.status === null) && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800'>
                  Rendicontazione in attesa di approvazione
                </span>
              )}
            {!paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.APPROVED && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                  Rendicontazione approvata
                </span>
              )}
            {!paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.REJECTED && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                  Rendicontazione con documenti mancanti
                </span>
              )}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            <div>
              <span className='text-sm text-gray-500'>Titolo</span>
              <p className='text-sm text-gray-900'>{course?.title}</p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Data inizio</span>
              <p className='text-sm text-gray-900'>
                {formatDate(course?.startDate || '', 'LL')}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Compenso preventivo</span>
              <p className='text-sm text-gray-900'>
                {formatCurrency(getEstimatedPayment() || 0)}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Ruolo</span>
              <p className='text-sm text-gray-900'>{role}</p>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>
            Dati Personali
          </h4>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <span className='text-sm text-gray-500'>Nome e Cognome</span>
              <p className='text-sm text-gray-900'>{`${courseModule.personalInfo?.firstName} ${courseModule.personalInfo?.lastName}`}</p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Codice Fiscale</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.fiscalCode}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Indirizzo</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.address}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Civico</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.streetNumber}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Città</span>
              <p className='text-sm text-gray-900'>{`${courseModule.personalInfo?.city} (${courseModule.personalInfo?.province}) ${courseModule.personalInfo?.zip}`}</p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Email</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.email}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Telefono</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.phone}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>IBAN</span>
              <p className='text-sm text-gray-900'>
                {courseModule.personalInfo?.iban}
              </p>
            </div>
          </div>
        </div>

        {/* Payment Information */}
        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>Compenso</h4>
          {courseModule.payment?.waivePayment ? (
            <p className='text-sm text-gray-500'>
              Il docente ha rinunciato al compenso
            </p>
          ) : (
            <div className='space-y-4'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <span className='text-sm text-gray-500'>Importo</span>
                  <p className='text-sm text-gray-900'>
                    {formatCurrency(courseModule.payment?.amount || 0)}
                  </p>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>Corso</span>
                  <p className='text-sm text-gray-900'>
                    {courseModule.payment?.courseName}
                  </p>
                </div>
                <div>
                  <p className='text-sm text-gray-900'>
                    {formatDate(
                      courseModule.payment?.courseStartDate || '',
                      'L'
                    )}
                  </p>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>Sezione</span>
                  <p className='text-sm text-gray-900'>
                    {courseModule.payment?.organizingSection}
                  </p>
                </div>
              </div>

              <div className='space-y-2'>
                <h5 className='text-sm font-medium text-gray-900'>
                  Dichiarazioni
                </h5>
                <div className='space-y-1'>
                  <p className='text-sm text-gray-500'>
                    Dipendente pubblico:{' '}
                    {courseModule.payment?.declarations?.isPublicEmployee ===
                    'YES'
                      ? 'Sì'
                      : 'No'}
                  </p>
                  {Object.entries(
                    courseModule.payment?.declarations?.autonomousWork || {}
                  ).map(([key, value]) => (
                    <p key={key} className='text-sm text-gray-500'>
                      {value ? '✓' : '✗'}{' '}
                      {
                        {
                          noTimeConstraints:
                            'Il sottoscritto non ha avuto obblighi di orario',
                          discretionaryExecution:
                            "Il sottoscritto è stato pienamente libero di svolgere l'incarico con discrezionalità nei riguardi delle modalità e dei tempi di esecuzione",
                          independentCriteria:
                            "I criteri seguiti nell'effettuare la prestazione sono stati del tutto indipendenti e personali, avendo il richiedente solo indicato il contenuto della prestazione richiesta",
                          agreedCompensation:
                            "la remunerazione è quella dovuta sulla base dell'offerta concordata",
                        }[key]
                      }
                    </p>
                  ))}

                  {/* Additional declarations */}
                  {Object.entries(courseModule.payment?.declarations || {})
                    .filter(
                      ([key]) =>
                        ![
                          'autonomousWork',
                          'isPublicEmployee',
                          'year',
                        ].includes(key)
                    )
                    .map(([key, value]) => (
                      <p key={key} className='text-sm text-gray-500'>
                        {value ? '✓' : '✗'}{' '}
                        {
                          {
                            taskCompletion:
                              "di aver svolto l'incarico/chi sopra riportato/i",
                            vatExemption:
                              "che non esercito per professione abituale attività di lavoro autonomo e che il compenso non è soggetto ad IVA (escluso ai sensi dell'art.5 del D.P.R. 633/72)",
                            taxWithholding:
                              "che il compenso è soggetto a ritenuta di acconto ai sensi dell'art.25 del D.P.R. 600/73",
                            socialSecurity:
                              "che tale compenso non è assoggettato a contributo previdenziale ai sensi dell'art. 44 D.L. 269/209 in quanto nel corso dell'anno solare sotto indicato il totale dei compensi ricevuti dal prestatore non supera i 5.000,00€",
                            noEmploymentClaim:
                              'che la suddetta prestazione resa occasionalmente, esclude da parte del dichiarante qualsiasi pretesa di riconoscimento di rapporto di lavoro dipendente',
                          }[key]
                        }
                        {key === 'socialSecurity' && (
                          <span className='text-sm text-gray-500 ml-2'>
                            (Anno {courseModule.payment?.declarations?.year})
                          </span>
                        )}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='bg-white shadow overflow-hidden sm:rounded-lg p-6'>
          <h4 className='text-lg font-IBM text-gray-600 mb-6'>
            Riepilogo Totali
          </h4>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
            <div>
              <span className='text-sm text-gray-500'>
                TOTALE LORDO COMPENSO
              </span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(
                  courseModule.payment?.waivePayment
                    ? 0
                    : courseModule.payment?.amount || 0
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>RITENUTA D'ACCONTO</span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(
                  (courseModule.payment?.waivePayment
                    ? 0
                    : courseModule.payment?.amount || 0) * 0.2
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>TOTALE NETTO</span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(
                  (courseModule.payment?.waivePayment
                    ? 0
                    : courseModule.payment?.amount || 0) * 0.8
                )}
              </p>
            </div>
          </div>
        </div>

        {/* Signature */}
        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>Firma</h4>
          <p className='text-sm text-gray-900'>{courseModule.signature}</p>
        </div>
      </div>
    );
  };

  const courseModule = reimbursement.attributes
    .module as DeepPartial<CourseReimbursementModule>;

  const role = reimbursement.attributes.treasurerCourse?.data
    ? 'Tesoriere'
    : 'Responsabile';

  function getPaymentDate() {
    if (role === 'Tesoriere') {
      return reimbursement.attributes.treasurerCourse?.data?.attributes
        ?.treasurerPaymentDate;
    }
    return reimbursement.attributes.supervisorCourse?.data?.attributes
      ?.supervisorPaymentDate;
  }

  function getCourse() {
    if (role === 'Tesoriere') {
      return reimbursement.attributes.treasurerCourse?.data?.attributes;
    }
    return reimbursement.attributes.supervisorCourse?.data?.attributes;
  }

  function getEstimatedPayment() {
    const course = getCourse();
    if (role === 'Tesoriere') {
      return course?.treasurerAmount;
    }
    return course?.supervisorAmount;
  }

  const paymentDate = getPaymentDate();

  const getInitialData = (): Partial<AdminCourseReimbursementFormData> => {
    const course = getCourse();
    const paymentDate = getPaymentDate();
    return {
      personalInfo: courseModule.personalInfo
        ? {
            firstName: courseModule.personalInfo.firstName || '',
            lastName: courseModule.personalInfo.lastName || '',
            address: courseModule.personalInfo.address || '',
            streetNumber: courseModule.personalInfo.streetNumber || '',
            city: courseModule.personalInfo.city || '',
            province: courseModule.personalInfo.province || '',
            zip: courseModule.personalInfo.zip || '',
            phone: courseModule.personalInfo.phone || '',
            email: courseModule.personalInfo.email || '',
            fiscalCode: courseModule.personalInfo.fiscalCode || '',
            iban: courseModule.personalInfo.iban || '',
          }
        : undefined,
      payment: {
        amount: courseModule.payment?.amount || 0,
        courseName: courseModule.payment?.courseName || '',
        organizingSection: courseModule.payment?.organizingSection || '',
        courseStartDate: courseModule.payment?.courseStartDate || '',
        paymentDate: paymentDate || undefined,
        role: reimbursement.attributes.treasurerCourse
          ? 'treasurer'
          : 'supervisor',
      },
      totalReimbursement: reimbursement.attributes.totalReimbursement || 0,
      note: reimbursement.attributes.note || undefined,
      attachments:
        reimbursement.attributes.attachments?.data?.map((attachment) => ({
          id: attachment.id,
          name: attachment.attributes.name,
          url: attachment.attributes.url,
        })) || [],
    };
  };

  const profile = reimbursement.attributes.profile?.data?.attributes;
  const { name, surname } = profile
    ? profile
    : {
        name: reimbursement.attributes.module.personalInfo?.firstName,
        surname: reimbursement.attributes.module.personalInfo?.lastName,
      };

  return (
    <>
      <tr>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {`${surname} ${name}`}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatDate(reimbursement.attributes.createdAt, 'L')}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatDate(courseModule?.payment?.courseStartDate || '', 'L')}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {courseModule?.payment?.organizingSection || ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {courseModule?.payment?.courseName || ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {role}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatCurrency(reimbursement.attributes.totalCompensation)}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {paymentDate ? formatDate(paymentDate, 'L') : ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {paymentDate ? (
            <select
              disabled
              className='rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-green-50 text-green-800'
              value='PAGATO'
            >
              <option value='PAGATO'>PAGATO</option>
            </select>
          ) : (
            <select
              value={reimbursement.attributes.status}
              onChange={(e) =>
                handleStatusChange(e.target.value as ReimbursementStatus)
              }
              className='rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm'
            >
              <option value={ReimbursementStatus.IN_REVIEW}>
                Da approvare
              </option>
              <option value={ReimbursementStatus.WAITING_COMPLETION}>
                Bozza
              </option>
              <option value={ReimbursementStatus.REJECTED}>
                Documentazione incompleta
              </option>
              <option value={ReimbursementStatus.APPROVED}>Approvato</option>
            </select>
          )}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          <button
            onClick={() => setIsNoteModalOpen(true)}
            className='text-primary hover:text-primary-dark'
          >
            {reimbursement.attributes.note ? 'Modifica nota' : 'Aggiungi nota'}
          </button>
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {courseModule && (
            <div className='flex items-center gap-2'>
              <button
                onClick={() => setIsDetailsModalOpen(true)}
                className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
              >
                <DocumentTextIcon className='h-4 w-4' />
                <span>Visualizza</span>
              </button>
              <button
                onClick={() => setIsEditModalOpen(true)}
                className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
              >
                <PencilIcon className='h-4 w-4' />
                <span>Modifica</span>
              </button>
              <PDFExportButton
                document={
                  <CourseReimbursementPDF reimbursement={reimbursement} />
                }
                fileName={`rimborso-corso-${
                  courseModule.payment?.courseName
                    ? `${courseModule.payment.courseName
                        .replace(/[^a-z0-9]/gi, '-')
                        .toLowerCase()}-${role.toLowerCase()}`
                    : `${reimbursement.id}-${role.toLowerCase()}`
                }.pdf`}
              />
            </div>
          )}
        </td>
      </tr>

      {/* Note Modal */}
      <BaseModal
        isOpen={isNoteModalOpen}
        toggle={() => setIsNoteModalOpen(false)}
        title={
          reimbursement.attributes.note ? 'Modifica nota' : 'Aggiungi nota'
        }
        onConfirm={handleNoteSubmit}
        onCancel={() => setIsNoteModalOpen(false)}
        isLoading={isSubmitting}
      >
        <textarea
          rows={4}
          className='shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md'
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </BaseModal>

      {/* Details Modal */}
      <BaseModal
        isOpen={isDetailsModalOpen}
        toggle={() => setIsDetailsModalOpen(false)}
        title='Dettagli Modulo'
        hideBottom
      >
        <div className='max-h-[80vh] overflow-y-auto px-4'>
          {renderDetailsContent()}
        </div>
      </BaseModal>

      {/* Attachments Modal */}
      <BaseModal
        isOpen={isAttachmentsModalOpen}
        toggle={() => setIsAttachmentsModalOpen(false)}
        title='Lista allegati'
        hideBottom
      >
        <div className='space-y-4'>
          {reimbursement.attributes.attachments?.data?.map(
            (attachment, index) => (
              <div key={index} className='flex items-center justify-between'>
                <span className='text-sm text-gray-600'>
                  {attachment.attributes.name}
                </span>
                <div className='flex items-center gap-4'>
                  <a
                    href={attachment.attributes.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => {
                      e.preventDefault();
                      // Create a server request to download the file
                      fetch(attachment.attributes.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          // Create a blob URL and trigger download
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            attachment.attributes.name
                          );
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                          window.URL.revokeObjectURL(url);
                        })
                        .catch((error) =>
                          console.error('Download failed:', error)
                        );
                    }}
                    className='text-primary hover:text-primary-dark text-sm font-medium'
                  >
                    Scarica
                  </a>
                  <a
                    href={attachment.attributes.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-primary hover:text-primary-dark text-sm font-medium'
                  >
                    Apri
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </BaseModal>

      {/* Edit Modal */}
      <AdminCourseReimbursementFormModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        key={isEditModalOpen ? 'open' : 'closed'}
        onSubmit={handleEdit}
        initialData={{
          status: reimbursement.attributes.status,
          module: reimbursement.attributes.module,
          totalCompensation: reimbursement.attributes.totalCompensation,
          note: reimbursement.attributes.note,
          attachments: reimbursement.attributes.attachments,
          treasurerCourse: reimbursement.attributes.treasurerCourse?.data?.id,
          supervisorCourse: reimbursement.attributes.supervisorCourse?.data?.id,
          profile: reimbursement.attributes.profile?.data?.id,
        }}
        isEdit
      />
    </>
  );
};

export default CourseReimbursementRow;
