import React from 'react';

import InputPresentational from './Form/InputPresentational';

import RoundedCheckboxPresentational from './Form/RoundedCheckboxPresentational';
import SelectPresentational from './Form/SelectPresentational';

import TextAreaPresentational from './Form/TextAreaPresentational';

import { useFormContext } from 'react-hook-form';
import { categories } from '../constants/categories';

import TeachersSelect from './FetchSelect/TeachersSelect';
import { isDelegato } from '../libs/utils/auth';
import { useAuth } from '../contexts/Auth';
import { toast } from 'react-toastify';
import { LessonTemplateAttributes } from '../interfaces/lesson-templates';

interface FormNewLessonProps {}
const FormLessonTemplate: React.FC<FormNewLessonProps> = ({}) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const [{ profile }] = useAuth();
  const teacherAutoAssignLessons = watch('teacherAutoAssignLessons');

  const onChangeEstimatePayment = (value: number) => {
    const limit = Number(watch('estimatePaymentLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('estimatePayment', limit);
      toast.error(
        "L'importo può essere aumentato solo dalla Segreteria Nazionale"
      );
      return;
    } else if (isDelegato(profile) && Number(value) < limit) {
      setValue('estimatePayment', value);
    }

    if (!isDelegato(profile)) setValue('estimatePayment', value);
  };

  return (
    <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6'>
      <div className='space-y-4'>
        <InputPresentational
          id='title'
          name='title'
          register={register}
          label='Titolo'
          type='text'
          required
          error={{ ...errors.title, type: 'required' }}
        />
        <TextAreaPresentational
          id='description'
          label='Descrizione'
          name='description'
          register={register}
          required
          error={{ ...errors.description, type: 'required' }}
        />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <SelectPresentational
          name='category'
          label='Categoria'
          options={categories}
          control={control}
          required
        />
        <div>
          <InputPresentational
            id='minutes'
            label='Durata (in minuti)'
            name='minutes'
            type='number'
            register={register}
            required
            error={{ ...errors.minutes, type: 'required' }}
          />
        </div>
        <div>
          <InputPresentational
            id='estimatePayment'
            label='Compenso preventivo docente'
            name='estimatePayment'
            type='number'
            value={watch('estimatePayment')}
            onChange={(e) => onChangeEstimatePayment(Number(e.target.value))}
            required
            error={{ ...errors.estimatePayment, type: 'required' }}
          />
        </div>
        {/* <InputPresentational
          id='estimateRefund'
          label='Rimborso preventivo docente'
          name='estimateRefund'
          type='text'
          register={register}
        /> */}
        <div className='h-full flex items-center -ml-3'>
          <RoundedCheckboxPresentational
            id='endCourse'
            describedby='endCourse'
            label='Fine corso'
            name='endCourse'
            register={register}
          />
        </div>
      </div>
      <div>
        {teacherAutoAssignLessons?.data && teacherAutoAssignLessons.data.length > 0 && (
          <div className="mb-4 text-sm text-gray-600">
            I <u>nuovi</u> docenti aggiunti saranno aggiunti anche alle seguenti lezioni: {teacherAutoAssignLessons.data.map((lesson: { id: number; attributes: LessonTemplateAttributes }) => lesson.attributes.title).join(', ')}
          </div>
        )}
        <TeachersSelect
          name='availableTeacher'
          id='availableTeacher'
          label='Docenti disponibili'
          control={control}
          required
          error={{ ...errors.availableTeacher, type: 'required' }}
        />
      </div>

      {/* <InputPresentational
        id='date'
        label='Data'
        name='date'
        type='date'
        register={register}
      /> */}
    </div>
  );
};

export default FormLessonTemplate;
