import moment from 'moment';
import { ReactNode, useMemo } from 'react';
import { useQuery } from 'react-query';
import appSettings from '../../api/appSettings';
import statisticsAPIs from '../../api/statisticsAPIs';
import { useAuth } from '../../contexts/Auth';
import { Statistics } from '../../interfaces/statistics';

export function useStatisticsYears(): [number, number, number] {
  const [{ token }] = useAuth();

  const appSettingsQuery = useQuery(['appSettings'], () =>
    appSettings.find({ token })
  );

  const showNextYear = useMemo(() => {
    if (!appSettingsQuery.data) return false;
    const [day, month] =
      appSettingsQuery.data?.data?.displayNextSubscriptionDayMonth?.split(
        '/'
      ) || [moment().day(), moment().month()];
    return moment(`${month}/${day}/${new Date().getFullYear()}`).isSameOrBefore(
      moment().toISOString()
    );
  }, [appSettingsQuery.data]);

  const currentYear = new Date().getFullYear();
  const last = showNextYear ? currentYear + 1 : currentYear;

  const years = [last, last - 1, last - 2] as [number, number, number];
  return years;
}

function calculateChange(
  a: number,
  b: number
): {
  absolute: ReactNode;
  percentage: ReactNode;
} {
  const difference = a - b;
  const percentageChange = ((a - b) / b) * 100;
  const percentage =
    b === 0
      ? '-'
      : (percentageChange >= 0 ? '+' : '') + percentageChange.toFixed(1) + '%';
  return {
    absolute: difference.toFixed(0),
    percentage,
  };
}

function calculateStatsChanges({
  reference,
  compare,
  value,
}: {
  reference: Statistics;
  compare: Statistics[];
  value: (statistics: Statistics) => number;
}) {
  return {
    values: [
      {
        value: value(reference),
        year: reference.year,
      },
      ...compare.map((c) => ({
        value: value(c),
        year: c.year,
      })),
    ],
    changes: compare.map((c) => ({
      referenceYear: reference.year,
      compareYear: c.year,
      change: calculateChange(value(reference), value(c)),
    })),
  };
}

function calculateComparison([reference, ...compare]: [
  Statistics & { until: Date },
  Statistics & { until: Date },
  Statistics & { until: Date }
]) {
  return {
    years: [
      { year: reference.year, until: reference.until },
      ...compare.map((c) => ({ year: c.year, until: c.until })),
    ],
    changes: [
      ...compare.map((c) => ({
        referenceYear: reference.year,
        compareYear: c.year,
      })),
    ],
    subscriptions: {
      total: calculateStatsChanges({
        reference,
        compare,
        value: (s) => s.subscriptionsReports.totals,
      }),
      juniors: calculateStatsChanges({
        reference,
        compare,
        value: (s) => s.subscriptionsReports.juniors,
      }),
      renewals: calculateStatsChanges({
        reference,
        compare,
        value: (s) => s.subscriptionsReports.renewals,
      }),
      reenrollments: calculateStatsChanges({
        reference,
        compare,
        value: (s) => s.subscriptionsReports.reenrollments,
      }),
      newSubs: calculateStatsChanges({
        reference,
        compare,
        value: (s) => s.subscriptionsReports.newSubs,
      }),
    },
  };
}

export function useComparisonStatistics({
  date: propsDate,
  sectionId,
}: {
  date?: string;
  sectionId?: string;
}) {
  const [{ token }] = useAuth();
  const years = useStatisticsYears();

  const isoDate = moment(propsDate).endOf('day').toISOString();

  return useQuery(
    ['comparisonStatistics', years, isoDate, sectionId],
    async () => {
      const stats = await Promise.all(
        years.map((year, index) => {
          const date = moment(propsDate).subtract(index, 'year').endOf('day');
          return statisticsAPIs
            .calculate({
              token,
              query: {
                year,
                date: date.toISOString(),
                sectionId,
              },
            })
            .then((s) => ({ ...s, until: date.toDate() }));
        })
      );

      return calculateComparison(
        stats as [
          Statistics & { until: Date },
          Statistics & { until: Date },
          Statistics & { until: Date }
        ]
      );
    }
  );
}
