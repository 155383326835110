import axios from 'axios';
import qs from 'qs';
import { Reimbursement } from '../interfaces/reimbursment';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';

const { REACT_APP_API_URL } = process.env;

export const listReimbursements = async (
  type: 'lesson' | 'course',
  searchParams: any,
  token: string | null
) => {
  const query = {
    populate: {
      profile: true,
      attachments: true,
      lesson: {
        populate: {
          course: {
            populate: ['section'],
          },
        },
      },
      treasurerCourse: true,
      supervisorCourse: true,
    },
    filters: {
      $and: [
        {
          module: { $notNull: true },
        },
        // Teacher name/surname search
        searchParams.get('search')
          ? {
              $or: [
                {
                  profile: {
                    surname: { $contains: searchParams.get('search') },
                  },
                },
                {
                  profile: {
                    name: { $contains: searchParams.get('search') },
                  },
                },
              ],
            }
          : {},
        // Course title search
        searchParams.get('courseTitle')
          ? type === 'lesson'
            ? {
                lesson: {
                  course: {
                    title: { $contains: searchParams.get('courseTitle') },
                  },
                },
              }
            : {
                $or: [
                  {
                    treasurerCourse: {
                      title: { $contains: searchParams.get('courseTitle') },
                    },
                  },
                  {
                    supervisorCourse: {
                      title: { $contains: searchParams.get('courseTitle') },
                    },
                  },
                ],
              }
          : {},
        // Section filter
        searchParams.get('sections')
          ? type === 'lesson'
            ? {
                lesson: {
                  course: {
                    section: {
                      id: {
                        $in: Object.entries(
                          qs.parse(searchParams.get('sections'))
                        )
                          .map(([, value]) => value)
                          .map((elem: any) => elem.value),
                      },
                    },
                  },
                },
              }
            : {
                $or: [
                  {
                    treasurerCourse: {
                      section: {
                        id: {
                          $in: Object.entries(
                            qs.parse(searchParams.get('sections'))
                          )
                            .map(([, value]) => value)
                            .map((elem: any) => elem.value),
                        },
                      },
                    },
                  },
                  {
                    supervisorCourse: {
                      section: {
                        id: {
                          $in: Object.entries(
                            qs.parse(searchParams.get('sections'))
                          )
                            .map(([, value]) => value)
                            .map((elem: any) => elem.value),
                        },
                      },
                    },
                  },
                ],
              }
          : {},
        // Role filter (course only)
        type === 'course' && searchParams.get('role')
          ? {
              $or: Object.entries(qs.parse(searchParams.get('role')))
                .map(([, value]) => value)
                .map((elem: any) => {
                  if (elem.value === 'treasurer') {
                    return { treasurerCourse: { id: { $notNull: true } } };
                  }
                  return { supervisorCourse: { id: { $notNull: true } } };
                }),
            }
          : {},
        // Status filter
        searchParams.get('status')
          ? type === 'lesson'
            ? {
                $or: Object.entries(qs.parse(searchParams.get('status')))
                  .map(([, value]) => value)
                  .map((elem: any) => {
                    if (elem.value === 'PAID') {
                      return {
                        lesson: {
                          paymentDate: { $notNull: true },
                        },
                      };
                    }
                    return {
                      status: elem.value,
                      lesson: {
                        paymentDate: { $null: true },
                      },
                    };
                  }),
              }
            : {
                $or: Object.entries(qs.parse(searchParams.get('status')))
                  .map(([, value]) => value)
                  .map((elem: any) => {
                    if (elem.value === 'PAID') {
                      return {
                        treasurerCourse: {
                          treasurerPaymentDate: { $notNull: true },
                        },
                      };
                    }
                    return {
                      status: elem.value,
                      $and: [
                        {
                          treasurerCourse: {
                            treasurerPaymentDate: { $null: true },
                          },
                        },
                        {
                          supervisorCourse: {
                            supervisorPaymentDate: { $null: true },
                          },
                        },
                      ],
                    };
                  }),
              }
          : {},
        // Date filters
        searchParams.get('courseDate')
          ? type === 'lesson'
            ? {
                lesson: {
                  date: {
                    $gte: qs.parse(
                      Object.entries(
                        qs.parse(searchParams.get('courseDate'))
                      ).map(([, value]) => value as any)[0]?.label
                    ).from,
                    $lte: qs.parse(
                      Object.entries(
                        qs.parse(searchParams.get('courseDate'))
                      ).map(([, value]) => value as any)[0]?.label
                    ).to,
                  },
                },
              }
            : {
                $or: [
                  {
                    treasurerCourse: {
                      startDate: {
                        $gte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('courseDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).from,
                        $lte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('courseDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).to,
                      },
                    },
                  },
                  {
                    supervisorCourse: {
                      startDate: {
                        $gte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('courseDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).from,
                        $lte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('courseDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).to,
                      },
                    },
                  },
                ],
              }
          : {},
        // Payment date filter
        searchParams.get('paymentDate')
          ? type === 'lesson'
            ? {
                lesson: {
                  paymentDate: {
                    $gte: qs.parse(
                      Object.entries(
                        qs.parse(searchParams.get('paymentDate'))
                      ).map(([, value]) => value as any)[0]?.label
                    ).from,
                    $lte: qs.parse(
                      Object.entries(
                        qs.parse(searchParams.get('paymentDate'))
                      ).map(([, value]) => value as any)[0]?.label
                    ).to,
                  },
                },
              }
            : {
                $or: [
                  {
                    treasurerCourse: {
                      treasurerPaymentDate: {
                        $gte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('paymentDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).from,
                        $lte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('paymentDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).to,
                      },
                    },
                  },
                  {
                    supervisorCourse: {
                      supervisorPaymentDate: {
                        $gte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('paymentDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).from,
                        $lte: qs.parse(
                          Object.entries(
                            qs.parse(searchParams.get('paymentDate'))
                          ).map(([, value]) => value as any)[0]?.label
                        ).to,
                      },
                    },
                  },
                ],
              }
          : {},
        // Type filter
        type === 'lesson'
          ? { lesson: { id: { $notNull: true } } }
          : type === 'course'
          ? {
              $or: [
                { treasurerCourse: { id: { $notNull: true } } },
                { supervisorCourse: { id: { $notNull: true } } },
              ],
            }
          : {},
      ],
    },
    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize') || 10,
    },
    sort: { updatedAt: 'desc' },
  };

  const { data } = await axios.get<StrapiResponseList<Reimbursement>>(
    `${REACT_APP_API_URL}/reimbursements?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const getReimbursement = async (id: number, token: string | null) => {
  const query = {
    populate: {
      profile: {
        populate: ['*'],
      },
      attachments: true,
      lesson: {
        populate: ['course', 'course.section'],
      },
    },
  };

  const { data } = await axios.get<StrapiResponse<Reimbursement>>(
    `${REACT_APP_API_URL}/reimbursements/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const updateReimbursement = async ({
  data,
  token,
  id,
}: {
  data: any;
  token: string | null;
  id: number;
}) => {
  const response = await axios.put(
    `${REACT_APP_API_URL}/reimbursements/${id}`,
    { data },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const downloadLessonReimbursementsCSV = async (
  searchParams: URLSearchParams,
  token: string | null
) => {
  const query = {
    populate: {
      profile: true,
      attachments: true,
      lesson: {
        populate: {
          course: {
            populate: ['section'],
          },
        },
      },
    },
    filters: {
      $and: [
        // Teacher name/surname search
        searchParams.get('search')
          ? {
              $or: [
                {
                  profile: {
                    surname: { $contains: searchParams.get('search') || '' },
                  },
                },
                {
                  profile: {
                    name: { $contains: searchParams.get('search') || '' },
                  },
                },
              ],
            }
          : {},
        // Course title search
        searchParams.get('courseTitle')
          ? {
              lesson: {
                course: {
                  title: { $contains: searchParams.get('courseTitle') || '' },
                },
              },
            }
          : {},
        // Section filter
        searchParams.get('sections')
          ? {
              lesson: {
                course: {
                  section: {
                    id: {
                      $in: Object.entries(
                        qs.parse(searchParams.get('sections') || '')
                      )
                        .map(([, value]) => value)
                        .map((elem: any) => elem.value),
                    },
                  },
                },
              },
            }
          : {},
        // Status filter
        searchParams.get('status')
          ? {
              $or: Object.entries(qs.parse(searchParams.get('status') || ''))
                .map(([, value]) => value)
                .map((elem: any) => {
                  if (elem.value === 'PAID') {
                    return {
                      lesson: {
                        paymentDate: { $notNull: true },
                      },
                    };
                  }
                  return {
                    status: elem.value,
                    lesson: {
                      paymentDate: { $null: true },
                    },
                  };
                }),
            }
          : {},
        // Date filters
        searchParams.get('lessonDate')
          ? {
              lesson: {
                date: {
                  $gte: qs.parse(
                    Object.entries(
                      qs.parse(searchParams.get('lessonDate') || '')
                    ).map(([, value]) => value as any)[0]?.label || ''
                  ).from,
                  $lte: qs.parse(
                    Object.entries(
                      qs.parse(searchParams.get('lessonDate') || '')
                    ).map(([, value]) => value as any)[0]?.label || ''
                  ).to,
                },
              },
            }
          : {},
        // Payment date filter
        searchParams.get('paymentDate')
          ? {
              lesson: {
                paymentDate: {
                  $gte: qs.parse(
                    Object.entries(
                      qs.parse(searchParams.get('paymentDate') || '')
                    ).map(([, value]) => value as any)[0]?.label || ''
                  ).from,
                  $lte: qs.parse(
                    Object.entries(
                      qs.parse(searchParams.get('paymentDate') || '')
                    ).map(([, value]) => value as any)[0]?.label || ''
                  ).to,
                },
              },
            }
          : {},
        { lesson: { id: { $notNull: true } } },
      ],
    },
    sort: { updatedAt: 'desc' },
  };

  const response = await axios.get(
    `${REACT_APP_API_URL}/reimbursements/lesson-csv?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'lesson-reimbursements.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCourseReimbursementsCSV = async (
  searchParams: URLSearchParams,
  token: string | null
) => {
  const query = {
    populate: {
      profile: true,
      treasurerCourse: true,
      supervisorCourse: true,
    },
    filters: {
      $and: [
        // Teacher name/surname search
        searchParams.get('search')
          ? {
              $or: [
                {
                  profile: {
                    surname: { $contains: searchParams.get('search') || '' },
                  },
                },
                {
                  profile: {
                    name: { $contains: searchParams.get('search') || '' },
                  },
                },
              ],
            }
          : {},
        // Course title search
        searchParams.get('courseTitle')
          ? {
              $or: [
                {
                  treasurerCourse: {
                    title: { $contains: searchParams.get('courseTitle') || '' },
                  },
                },
                {
                  supervisorCourse: {
                    title: { $contains: searchParams.get('courseTitle') || '' },
                  },
                },
              ],
            }
          : {},
        // Section filter
        searchParams.get('sections')
          ? {
              $or: [
                {
                  treasurerCourse: {
                    section: {
                      id: {
                        $in: Object.entries(
                          qs.parse(searchParams.get('sections') || '')
                        )
                          .map(([, value]) => value)
                          .map((elem: any) => elem.value),
                      },
                    },
                  },
                },
                {
                  supervisorCourse: {
                    section: {
                      id: {
                        $in: Object.entries(
                          qs.parse(searchParams.get('sections') || '')
                        )
                          .map(([, value]) => value)
                          .map((elem: any) => elem.value),
                      },
                    },
                  },
                },
              ],
            }
          : {},
        // Role filter
        searchParams.get('role')
          ? {
              $or: Object.entries(qs.parse(searchParams.get('role') || ''))
                .map(([, value]) => value)
                .map((elem: any) => {
                  if (elem.value === 'treasurer') {
                    return { treasurerCourse: { id: { $notNull: true } } };
                  }
                  return { supervisorCourse: { id: { $notNull: true } } };
                }),
            }
          : {},
        // Status filter
        searchParams.get('status')
          ? {
              $or: Object.entries(qs.parse(searchParams.get('status') || ''))
                .map(([, value]) => value)
                .map((elem: any) => {
                  if (elem.value === 'PAID') {
                    return {
                      treasurerCourse: {
                        treasurerPaymentDate: { $notNull: true },
                      },
                    };
                  }
                  return {
                    status: elem.value,
                    $and: [
                      {
                        treasurerCourse: {
                          treasurerPaymentDate: { $null: true },
                        },
                      },
                      {
                        supervisorCourse: {
                          supervisorPaymentDate: { $null: true },
                        },
                      },
                    ],
                  };
                }),
            }
          : {},
        // Date filters
        searchParams.get('courseDate')
          ? {
              $or: [
                {
                  treasurerCourse: {
                    startDate: {
                      $gte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('courseDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).from,
                      $lte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('courseDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).to,
                    },
                  },
                },
                {
                  supervisorCourse: {
                    startDate: {
                      $gte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('courseDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).from,
                      $lte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('courseDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).to,
                    },
                  },
                },
              ],
            }
          : {},
        // Payment date filter
        searchParams.get('paymentDate')
          ? {
              $or: [
                {
                  treasurerCourse: {
                    treasurerPaymentDate: {
                      $gte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('paymentDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).from,
                      $lte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('paymentDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).to,
                    },
                  },
                },
                {
                  supervisorCourse: {
                    supervisorPaymentDate: {
                      $gte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('paymentDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).from,
                      $lte: qs.parse(
                        Object.entries(
                          qs.parse(searchParams.get('paymentDate') || '')
                        ).map(([, value]) => value as any)[0]?.label || ''
                      ).to,
                    },
                  },
                },
              ],
            }
          : {},
        {
          $or: [
            { treasurerCourse: { id: { $notNull: true } } },
            { supervisorCourse: { id: { $notNull: true } } },
          ],
        },
      ],
    },
    sort: { updatedAt: 'desc' },
  };

  const response = await axios.get(
    `${REACT_APP_API_URL}/reimbursements/course-csv?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'course-reimbursements.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const createAdminLessonReimbursement = async (
  data: FormData | object,
  token: string | null
): Promise<StrapiResponse<Reimbursement>> => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/reimbursements`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const createAdminCourseReimbursement = async (
  data: FormData | object,
  token: string | null
): Promise<StrapiResponse<Reimbursement>> => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/reimbursements`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateAdminLessonReimbursement = async (
  id: number,
  data: FormData | object,
  token: string | null
): Promise<StrapiResponse<Reimbursement>> => {
  const response = await axios.put(
    `${REACT_APP_API_URL}/reimbursements/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateAdminCourseReimbursement = async (
  id: number,
  data: FormData | object,
  token: string | null
): Promise<StrapiResponse<Reimbursement>> => {
  const response = await axios.put(
    `${REACT_APP_API_URL}/reimbursements/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
