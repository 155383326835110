/**
 * N.B. -> Quanto fatto di seguito non è da prendere come esempio, ma con le pinze.
 */
import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import SendButton from '../Buttons/SandButton';
import * as Yup from 'yup';
import InputPresentational from '../Form/InputPresentational';
import PriceInputPresentational from '../Form/PriceInputPresentational';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/Auth';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { isDelegato } from '../../libs/utils/auth';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { CourseRequest } from '../../interfaces/courses';
import { updateCourse } from '../../api/courses';

interface RegisterCourseTreasurerPaymentModalProps {
  course?: Partial<CourseRequest>;
}

const RegisterCourseTreasurerPaymentModal: React.FC<
  RegisterCourseTreasurerPaymentModalProps
> = ({ course }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [{ token, profile }] = useAuth();

  const { id: courseId } = useParams();
  const queryClient = useQueryClient();

  const toggleModal = () => {
    setOpen((v) => !v);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    watch,
  } = useForm<Partial<CourseRequest>>({
    resolver: useYupValidationResolver(
      Yup.object().shape({
        treasurerPaymentDate: Yup.date()
          .transform((value: Date) => {
            const withoutTimezone = new Date(
              Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
            );
            return withoutTimezone;
          })
          .typeError('Valore non validao')
          .required('Questo campo è obbligatorio'),
        treasurerAmountPaid: Yup.number()
          .min(0)
          .typeError('Valore non validao')
          .required('Questo campo è obbligatorio'),
      })
    ),
  });

  const { mutate, isLoading: isUpdating } = useMutation(
    ['addPaymentForCourseManagement'],
    ({
      courseId,
      values,
      token,
    }: {
      courseId: number;
      values: Partial<CourseRequest>;
      token: string | null;
    }) => updateCourse(courseId, values as CourseRequest, token),
    {
      onSuccess: () => {
        toast.success('Operazione conclusa con successo');
        setOpen(false);
        queryClient.invalidateQueries({ queryKey: ['course', courseId] });
      },
    }
  );

  const onSubmit = (values: Partial<CourseRequest>) => {
    if (
      Number(watch('treasurerAmountPaid') || 0) >
      Number(course?.treasurerAmount)
    ) {
      return setError('treasurerAmountPaid', {
        message: `Il compenso non può essere superiore a ${course?.treasurerAmount} €`,
      });
    }

    mutate({
      courseId: Number(courseId),
      values: { ...course, ...values },
      token,
    });
  };

  useEffect(() => {
    if (!open) {
      reset({});
    } else {
      reset({
        treasurerPaymentDate: course?.treasurerPaymentDate,
        treasurerAmountPaid: course?.treasurerAmountPaid,
      });
    }
  }, [course, open]);

  /**
   * Il form della direzione è presente in vari punti dell'applicazione e i pagamenti
   * si possono registrare soltanto in fase di modifica. Il courseId identifica se sei nel dettaglio, quindi se si sta modificando
   * Lo prendo come riferimento e se non c'è ritorno un fragment
   *
   * Lo stesso nel caso in cui l'utente loggato è un delegato
   */
  if (!courseId || isDelegato(profile)) return <></>;

  return (
    <>
      <UnderlineButton
        small
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <CurrencyEuroIcon className='w-4 h-4' /> Registra pagamento
      </UnderlineButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div
            className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'
            onClick={(e) => e.stopPropagation()}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Registra pagamento tesoriere corso
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='bg-neutral-100 px-4 py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4'>
                    <InputPresentational
                      id='paymentDate'
                      label='Data'
                      name='treasurerPaymentDate'
                      type='date'
                      required
                      register={register}
                      error={errors.treasurerPaymentDate}
                    />
                    <PriceInputPresentational
                      id='actualRefund'
                      label='Compenso'
                      name='treasurerAmountPaid'
                      type='text'
                      error={errors.treasurerAmountPaid}
                      register={register}
                    />
                  </div>

                  <div className='flex items-center justify-end gap-4 mt-6'>
                    <PrimaryButton textSmall onClick={toggleModal}>
                      Annulla
                    </PrimaryButton>
                    <SendButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      smallRadius
                      disabled={isUpdating}
                      isLoading={isUpdating}
                    >
                      Registra pagamento
                    </SendButton>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RegisterCourseTreasurerPaymentModal;
