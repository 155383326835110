import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getCourseDetail,
  updateCourse,
  uploadToStrapi,
} from '../../../api/courses';
import HeaderTitle from '../../../components/HeaderTitle';
import StatusCourseModal from '../../../components/Modals/StatusCourseModal';
import TabBar from '../../../components/TabBars/TabBar';
import { emptyCourseTemplate } from '../../../constants/course-templates';
import { useAuth } from '../../../contexts/Auth';
import { isDelegato, isSegreteriaNazionale } from '../../../libs/utils/auth';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import CostiCorso from '../Shared/costi';
import CreaRicevutaCorso from './crea-ricevuta';
import CreaRicevutaTemplateCorso from './crea-ricevuta-template';
import DatiPrincipaliCorso from './dati-principali';
import DirezioneCorso from './direzione';
import LezioniCorsi from './lezioni';
import LocationCorso from './location';
import AggiungiLezioneCorso from '../Shared/aggiungi-lezione';
import ModificaLezioneCorso from '../Shared/modifica-lezione';
import PartecipantiCorso from './partecipanti';
import RicevuteCorso from './ricevute';
import { CourseRequest, CourseResponse } from '../../../interfaces/courses';
import {
  DescriptionList,
  CourseStatus,
  StrapiResponse,
} from '../../../interfaces/commons';
import { IOption } from '../../../components/Form/Multiselect';
import ModificaRicevutaCorso from './modifica-ricevuta';
import { IUser } from '../../../interfaces/login';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import BaseModal from '../../../components/Modals/BaseModal';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { courseValidator } from '../../../validators/course';
import { deleteMedia } from '../../../libs/utils/media';
import { checkProfile } from '../../../libs/utils/helpers';
import FullscreenSpinner from '../../../components/Layout/Loading/FullscreenSpinner';
import locationsAPIs from '../../../api/locationsAPIs';
import _ from 'lodash';

const DettaglioCorso = () => {
  const { id } = useParams();
  const [{ profile, token }] = useAuth();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const courseFormMethods = useForm<CourseRequest>({
    defaultValues: { ...emptyCourseTemplate },
    resolver: useYupValidationResolver(courseValidator),
  });
  const isDirty = courseFormMethods.formState.isDirty;
  const formValues = useMemo(
    () => courseFormMethods.watch(),
    [courseFormMethods.watch('endDate')]
  );

  const tabs = [
    {
      label: 'Dati principali',
      href: `/corsi/dettaglio-corso/${id}/dati-principali`,
    },
    {
      label: 'Location',
      href: `/corsi/dettaglio-corso/${id}/location`,
    },
    {
      label: 'Partecipanti',
      href: `/corsi/dettaglio-corso/${id}/partecipanti`,
    },
    {
      label: 'Lezioni',
      href: `/corsi/dettaglio-corso/${id}/lezioni`,
    },
    {
      label: 'Direzione',
      href: `/corsi/dettaglio-corso/${id}/direzione`,
    },
    {
      label: 'Costi',
      href: `/corsi/dettaglio-corso/${id}/costi`,
    },
    {
      label: 'Ricevute',
      href: `/corsi/dettaglio-corso/${id}/ricevute`,
    },
  ];

  const courseDetailQueryFn = () => {
    return getCourseDetail(id!, token);
  };

  const onCourseDetailQuerySuccess = (data: StrapiResponse<CourseResponse>) => {
    const { attributes: courseResponseAttributes } = data.data;

    const lessons = courseResponseAttributes.lessons?.data;
    const lessonIds = lessons?.map((lesson) => lesson.id);

    const estimatedTeacherPayment =
      lessons?.reduce(
        (total, lesson) => total + lesson.attributes.estimatePayment,
        0
      ) || 0;
    const actualTeacherPayment =
      lessons?.reduce(
        (total, lesson) => total + (lesson.attributes.actualPayment || 0),
        0
      ) || 0;
    const estimatedTeacherRefund =
      lessons?.reduce(
        (total, lesson) => total + (lesson.attributes.estimateRefund || 0),
        0
      ) || 0;
    const actualTeacherRefund =
      lessons?.reduce(
        (total, lesson) => total + (lesson.attributes.actualRefund || 0),
        0
      ) || 0;

    const courseRequest: CourseRequest = {
      ...courseResponseAttributes,
      supervisorReimbursement:
        courseResponseAttributes.supervisorReimbursement?.data?.id,
      treasurerReimbursement:
        courseResponseAttributes.treasurerReimbursement?.data?.id,
      mailDate: formatDate(courseResponseAttributes.mailDate),
      firstReminderDate: formatDate(courseResponseAttributes.firstReminderDate),
      secondReminderDate: formatDate(
        courseResponseAttributes.secondReminderDate
      ),
      location: courseResponseAttributes.location?.data?.id,
      section: courseResponseAttributes.section?.data?.id || null,
      courseTemplate: courseResponseAttributes.courseTemplate?.data?.id,
      delegate: courseResponseAttributes.delegate?.data?.id,
      supervisor: courseResponseAttributes.supervisor?.data?.id,
      treasurer: courseResponseAttributes.treasurer?.data?.id,
      minProfileCategorySub:
        courseResponseAttributes.minProfileCategorySub?.data?.id,
      lessons: lessonIds || [],
      balance: {
        ...courseResponseAttributes.balance,
        teacherPayments: {
          ...courseResponseAttributes.balance.teacherPayments,
          estimatedOutflow: estimatedTeacherPayment,
          finalOutflow: actualTeacherPayment,
        },
        teacherRefunds: {
          ...courseResponseAttributes.balance.teacherRefunds,
          estimatedOutflow: estimatedTeacherRefund,
          finalOutflow: actualTeacherRefund,
        },
      },
      carousel:
        courseResponseAttributes.carousel?.data?.map((carousel) => carousel) ||
        [],
      image: courseResponseAttributes.image?.data,
      partecipations: courseResponseAttributes.partecipations,
      safetyMargin: courseResponseAttributes.safetyMargin || 0,
      canDelegateEdit: courseResponseAttributes.canDelegateEdit || false,
      disableSecondLevel:
        ![undefined, null, CourseStatus.DRAFT].includes(
          courseResponseAttributes.status
        ) && isDelegato(profile),
      initialMaxParticipants: courseResponseAttributes.maxParticipants,
    };
    courseFormMethods.reset(courseRequest);
  };

  const onCourseDetailQueryError = () => {
    toast.error('Errore nel recupero dei dati');
    navigate('/404');
  };

  const courseDetailQuery = useQuery({
    queryKey: ['course', id],
    queryFn: courseDetailQueryFn,
    onSuccess: onCourseDetailQuerySuccess,
    onError: onCourseDetailQueryError,
    refetchOnReconnect: false,
    retry: 0,
  });

  const { mutateAsync: uploadFileMutation, isLoading: isUploadingFile } =
    useMutation({
      mutationKey: ['uploadFile'],
      mutationFn: ({ files, token }: { files: File[]; token: string | null }) =>
        uploadToStrapi(files, token),

      onError: (err) => {
        console.log('err', err);
        toast.error('Qualcosa è andato storto!');
      },
    });

  const { mutateAsync: saveCourseMutation, isLoading: isSavingCourse } =
    useMutation({
      mutationKey: ['saveCourse'],
      mutationFn: ({
        courseId,
        updatedCourse,
      }: {
        courseId: number;
        updatedCourse: CourseRequest;
      }) => updateCourse(courseId, updatedCourse, token),
      onSuccess: () => {
        toast.success('Corso salvato con successo!');
        navigate('/corsi');
      },
      onError: (err) => {
        console.log('err', err);
        toast.error('Qualcosa è andato storto!');
      },
    });

  const checkLocations = async () => {
    const section = courseFormMethods.watch('section');
    const selectedLocation = courseFormMethods.watch('location');
    if (!section) return;
    const { data: locations } = await locationsAPIs.find({
      token,
      query: {
        filters: { sections: { id: section } },
        pagination: { pageSize: 500 },
      },
    });

    const isLoacationAllowed = locations.some(
      (location) => location.id === selectedLocation
    );
    if (!isLoacationAllowed) courseFormMethods.setValue('location', undefined);
  };

  useEffect(() => {
    checkLocations();
  }, [courseFormMethods.watch('section')]);

  const canDelegatoEdit = courseFormMethods.watch('canDelegateEdit');

  const save = (status?: CourseStatus) => {
    return courseFormMethods.handleSubmit(async (updatedEventValues) => {
      if (checkProfile(profile, canDelegatoEdit)) return;
      const course = {
        ...courseFormMethods.getValues(),
        location: courseFormMethods.getValues().location as IOption,
        treasurerAmountLimit: isDelegato(profile)
          ? courseFormMethods.getValues().treasurerAmountLimit
          : courseFormMethods.getValues().treasurerAmount,
        supervisorAmountLimit: isDelegato(profile)
          ? courseFormMethods.getValues().supervisorAmountLimit
          : courseFormMethods.getValues().supervisorAmount,
      };
      let uploadCourseImageRes: any;
      let uploadCourseCarouselRes: any;

      const areElementsFile = (elements?: any[] | null) => {
        return elements?.some((element) => element instanceof File);
      };

      let existingCarouselIds: any[] = [];
      if (Array.isArray(course.carousel)) {
        existingCarouselIds = course.carousel.map((item: any) => item.id);
      }

      if (course.image) {
        uploadCourseImageRes = await uploadFileMutation({
          files: course.image as File[],
          token,
        });
      }

      if (areElementsFile(course.carousel)) {
        uploadCourseCarouselRes = await uploadFileMutation({
          files: course.carousel as File[],
          token,
        });
      }

      const newCarouselIds = uploadCourseCarouselRes?.map(
        (file: any) => file.id
      );

      const updatedCourse = {
        ...course,
        location: course.location ? Number(course.location) : null,
        delegate: null,
        status: status || course.status,
        mailDate: moment(course.mailDate).utc().toISOString(),
        firstReminderDate: moment(course.firstReminderDate).utc().toISOString(),
        secondReminderDate: moment(course.secondReminderDate)
          .utc()
          .toISOString(),
        details: {
          ...course.details,
          otherInfos: course.details?.otherInfos?.map(
            (gadget: DescriptionList) => ({
              description: gadget.description,
              id: gadget.id,
            })
          ),
        },
        image: uploadCourseImageRes?.[0]?.id,
        carousel: [...existingCarouselIds, ...(newCarouselIds || [])],
      };

      saveCourseMutation({
        courseId: Number(id!),
        updatedCourse: _.omit(updatedCourse, [
          'balance.couponsVirtuosity',
        ]) as CourseRequest,
      });
    });
  };

  const formHasErrors: boolean =
    Object.keys(courseFormMethods.formState.errors).length > 0;

  const saveAsDraft = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione, controlla tutti i campi.');
    } else {
      save(CourseStatus.DRAFT)();
    }
  };

  const saveAndPublish = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione, controlla tutti i campi.');
    } else {
      save(CourseStatus.PUBLISHED)();
    }
  };

  const saveAsNotApproved = () => {
    if (formHasErrors) {
      toast.error('Errore di validazione, controlla tutti i campi.');
    } else {
      save(CourseStatus.NOT_APPROVED)();
    }
  };

  const getButtons = (profile: IUser) => {
    const { status: courseStatus, endDate } = formValues;
    const isCourseEnded = moment().isAfter(moment(endDate));

    if (isSegreteriaNazionale(profile))
      switch (courseStatus) {
        case CourseStatus.DRAFT:
        case CourseStatus.NOT_APPROVED:
          return {
            primaryButtonText: 'Pubblica',
            primaryButtonOnClick: saveAndPublish,
            secondaryButtonText: 'Salva bozza',
            secondaryButtonOnClick: saveAsDraft,
          };
        case CourseStatus.TO_APPROVE:
          return {
            primaryButtonText: 'Approva e pubblica',
            primaryButtonOnClick: saveAndPublish,
            secondaryButtonText: 'Non approvare',
            secondaryButtonOnClick: saveAsNotApproved,
          };
        case CourseStatus.PUBLISHED:
          return {
            primaryButtonText: isCourseEnded
              ? 'Rendiconta corso'
              : 'Pubblica modifiche',
            primaryButtonOnClick: isCourseEnded
              ? save(CourseStatus.FINAL_BALANCE_DELEGATION)
              : save(),
            secondaryButtonText: isCourseEnded ? 'Salva modifiche' : undefined,
            secondaryButtonOnClick: save(),
          };
        case CourseStatus.FINAL_BALANCE_DELEGATION:
          return {
            primaryButtonText: isCourseEnded
              ? 'Rendiconta corso'
              : 'Salva modifiche',
            primaryButtonOnClick: isCourseEnded
              ? save(CourseStatus.FINAL_BALANCE_NATIONAL)
              : save(),
            secondaryButtonText: isCourseEnded ? 'Salva modifiche' : undefined,
            secondaryButtonOnClick: save(),
          };
      }
    else
      switch (courseStatus) {
        case CourseStatus.DRAFT:
        case CourseStatus.NOT_APPROVED:
          return {
            primaryButtonText: 'Richiedi approvazione',
            primaryButtonOnClick: save(CourseStatus.TO_APPROVE),
            secondaryButtonText: 'Salva bozza',
            secondaryButtonOnClick: save(CourseStatus.DRAFT),
          };
        case CourseStatus.TO_APPROVE:
          return {
            primaryButtonText: 'Richiedi approvazione',
            secondaryButtonText: 'Salva bozza',
            buttonsDisabled: true,
          };
        case CourseStatus.PUBLISHED:
          return isCourseEnded
            ? {
                primaryButtonText: 'Rendiconta corso',
                primaryButtonOnClick: save(
                  CourseStatus.FINAL_BALANCE_DELEGATION
                ),
                secondaryButtonText: 'Salva modifiche',
                secondaryButtonOnClick: save(),
              }
            : {
                primaryButtonText: 'Pubblica modifiche',
                primaryButtonOnClick: saveAndPublish,
              };
      }
  };

  const onImageDelete = async (fileId?: number) => {
    //e' il file di strapi
    if (fileId) {
      //cancello il file su strapi
      try {
        await deleteMedia({ fileId, token });
        courseDetailQuery.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error("Qualcosa e' andato storto");
      }
    }
    //e' il file del form
    else {
      courseFormMethods.setValue('image', undefined);
    }
  };

  const onCarouselImageDelete = async (index: number) => {
    const carouselImages = Array.isArray(formValues.carousel)
      ? [...formValues.carousel]
      : [];
    const carouselImagesLoaded =
      courseDetailQuery.data?.data.attributes.carousel?.data || [];
    // se in Strapi sono presenti immagini nel carosello le cancello da strapi
    if (index >= 0 && index < (carouselImagesLoaded?.length || 0)) {
      const imageId = carouselImagesLoaded[index].id;
      try {
        await deleteMedia({ fileId: imageId, token });
        courseDetailQuery.refetch();
      } catch (err) {
        console.log('err', err);
        toast.error('Qualcosa è andato storto');
      }
      // se le immagini del carosello non sono state caricate su strapi ma solo nel form
    } else {
      carouselImages?.splice(index, 1); // Rimuovi l'immagine dall'array
      courseFormMethods.setValue('carousel', carouselImages as File[]);
    }
  };

  if (courseDetailQuery.isFetching) return <FullscreenSpinner />;

  return (
    <FormProvider {...courseFormMethods}>
      <form className='mt-6' onSubmit={(e) => e.preventDefault()}>
        <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
          {profile && (
            <HeaderTitle
              category='Corso'
              modal={<StatusCourseModal defaultStatus={formValues.status} />}
              // preview      TODO
              {...getButtons(profile)}
              buttonsDisabled={
                checkProfile(profile, canDelegatoEdit) ||
                isSavingCourse ||
                isUploadingFile
              }
            />
          )}

          <BaseModal
            title={'Corso non rendicontato da sezione'}
            isOpen={isOpen}
            toggle={toggleModal}
            className={'mx-auto md:w-[500px] w-11/12'}
            hideBottom
          >
            <>
              Non è possibile consuntivare il corso perchè non è ancora in stato{' '}
              <strong>consuntivato da sezione</strong>.
              <div className='flex items-center justify-end gap-4 mt-6'>
                <PrimaryButton onClick={toggleModal} small>
                  {'OK'}
                </PrimaryButton>
              </div>
            </>
          </BaseModal>

          <div className='pt-6'>
            <TabBar tabs={tabs} />
            <Routes>
              <Route>
                <Route
                  path='dati-principali'
                  element={
                    <DatiPrincipaliCorso
                      onImageDelete={() =>
                        onImageDelete(
                          courseDetailQuery.data?.data?.attributes.image?.data
                            ?.id
                        )
                      }
                      onCarouselImageDelete={(index) =>
                        onCarouselImageDelete(index)
                      }
                      courseMethods={courseFormMethods}
                    />
                  }
                />
                <Route path='location' element={<LocationCorso />} />
                <Route
                  path='partecipanti'
                  element={
                    <PartecipantiCorso courseDetailQuery={courseDetailQuery} />
                  }
                />
                <Route
                  path='partecipanti/:participantId/ricevute'
                  element={<CreaRicevutaTemplateCorso />}
                />
                <Route
                  path='ricevute/:receiptId'
                  element={<ModificaRicevutaCorso />}
                />

                <Route
                  path='lezioni/modifica-lezione/:id'
                  element={<ModificaLezioneCorso courseId={id} />}
                />
                <Route path='direzione' element={<DirezioneCorso />} />
                <Route
                  path='costi'
                  element={
                    <CostiCorso
                      title='Dettaglio corso'
                      defaultCourse={courseDetailQuery.data?.data.attributes}
                    />
                  }
                />
                <Route
                  path='ricevute'
                  element={
                    <RicevuteCorso courseDetailQuery={courseDetailQuery} />
                  }
                />
              </Route>
            </Routes>
          </div>
        </div>
      </form>
      <Routes>
        <Route>
          <Route
            path='partecipanti/:participantId/ricevute/crea'
            element={<CreaRicevutaCorso />}
          />
        </Route>
      </Routes>

      <Routes>
        <Route
          path='lezioni'
          element={
            <LezioniCorsi
              courseDetailQuery={courseDetailQuery}
              isDirty={isDirty}
            />
          }
        />
        <Route
          path='lezioni/aggiungi-lezione'
          element={<AggiungiLezioneCorso />}
        />
      </Routes>
    </FormProvider>
  );
};
export default DettaglioCorso;
