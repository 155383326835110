import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { SearchableSelect } from '../Form/SearchableSelect';
import { getLesson, getLessons } from '../../api/lesson';
import { useAuth } from '../../contexts/Auth';
import { FieldError, useWatch } from 'react-hook-form';
import { CourseLessonResponse } from '../../interfaces/lesson';
import moment from 'moment';

interface LessonSearchSelectProps {
  name: string;
  label?: string;
  control: any;
  error?: FieldError;
  defaultValue?: number;
  required?: boolean;
  onLessonSelect?: (lesson: CourseLessonResponse) => void;
  lessonCourse?: number | null;
}

const LessonSearchSelect: React.FC<LessonSearchSelectProps> = ({
  name,
  label = 'Lezione',
  control,
  error,
  defaultValue,
  required,
  onLessonSelect,
  lessonCourse,
}) => {
  const [{ token }] = useAuth();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 300);
  const value = useWatch({ control, name }) as number;

  const { data: lessonsData, isFetching } = useQuery(
    ['lessons-select', lessonCourse, debouncedQuery],
    async () => {
      const selected = value
        ? await getLesson({
            id: value.toString(),
            token,
            populate: ['course', 'course.section'],
          })
        : null;
      const lessons = await getLessons({
        filters: {
          $and: [
            {
              course: {
                id: lessonCourse
                  ? lessonCourse
                  : {
                      $notNull: true,
                    },
              },
            },
            ...(searchQuery
              ? [
                  {
                    $or: [{ title: { $contains: searchQuery } }],
                  },
                ]
              : []),
          ],
        },
        populate: ['course', 'course.section'],
        token,
        searchParams: new URLSearchParams({ pageSize: '20' }),
      });

      if (!selected || !selected?.data) return lessons;

      return {
        ...lessons,
        data: [selected.data, ...lessons.data],
      };
    },
    {
      enabled: !!token,
    }
  );

  const formatOptionLabel = useCallback((lesson: CourseLessonResponse) => {
    const lessonTitle = lesson.attributes.title;
    const lessonDate = moment(lesson.attributes.date).format('DD/MM/YYYY');
    return `${lessonTitle} - (${lessonDate})`;
  }, []);

  const options = React.useMemo(() => {
    if (!lessonsData?.data) return [];
    return lessonsData.data.map((lesson) => ({
      value: lesson.id,
      label: formatOptionLabel(lesson),
      lesson,
    }));
  }, [lessonsData, formatOptionLabel]);

  const handleInputChange = (input: string) => {
    setSearchQuery(input);
  };

  return (
    <SearchableSelect
      name={name}
      label={`${label}${required ? ' *' : ''}`}
      control={control}
      error={error}
      options={options}
      onInput={handleInputChange}
      isLoading={isFetching}
      defaultValue={defaultValue}
      onChange={(op: (typeof options)[number]) => {
        onLessonSelect?.(op?.lesson);
      }}
      registerOptions={{
        setValueAs: (value) => {
          return value.value;
        },
      }}
    />
  );
};

export default LessonSearchSelect;
