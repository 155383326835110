import { IOption } from '../components/Form/Multiselect';
import { Category } from './commons';
import { CourseResponse } from './courses';
import { IUser } from './login';
import { Media } from './media';
import { Profile, ProfileAttributes } from './profile';
import { Reimbursement } from './reimbursment';

export interface CourseLessonCommonAttributes {
  title: string;
  description: string;
  minutes: number;
  estimatePayment: number;
  estimatePaymentLimit: number;
  estimateRefund: number;
  endCourse?: boolean;
  date: string;
  waiverTeacher?: string;
  lessonTemplate?:
    | {
        data: any;
      }
    | undefined;
  wineList?: string;
  actualPayment?: number;
  actualRefund?: number;
  category?: Category;
  order?: number;
  paymentDate?: string;
  teachersAcceptanceStatus?: TeachersAcceptanceStatus;
  areSpendingsReceived?: boolean;
  slidePdf?: File[];
  isWaiverTeacher?: boolean;
  isMemberTeacher?: boolean;
  isOnline?: boolean;
  sendEmailNotificationToTeacher?: boolean;
  emailToTeacherSent?: boolean;
}

export type CourseLessonRequest = CourseLessonCommonAttributes & {
  course?: number;
  teacher?: number | IOption | null;
  oldTeacher?: number | IOption | null;
  isTeacherChanged?: boolean;
  availableTeacher?: number[] | IOption[];
  sendEmailNotificationToTeacherOldStatus?: boolean;
  reimbursement?: number;
};

export type BulkUpdateLessonsDataRequest = {
  data: Partial<CourseLessonRequest & { id?: number }>[];
};

export interface CourseLessonResponse {
  id: number;
  attributes: CourseLessonCommonAttributes & {
    reimbursement?: { data: { id: number } };
    teacher?: { data: Profile };
    availableTeacher?: { data: Profile[] };
    course?: { data: CourseResponse };
    slidePdf?: { data: Media };
    createdAt: string;
    updatedAt: string;
    lessonTemplate?: { data: LessonTemplate };
    userActions?: UserActionType[];
  };
}

export enum UserAction {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

export enum UserActionEffect {
  SEND_TEACHER_EMAIL = 'SEND_TEACHER_EMAIL',
}

export type UserActionType = {
  action: UserAction;
  date: string;
  effect: UserActionEffect;
  id: number;
  user: IUser & { profile: ProfileAttributes };
};

export interface LessonTemplate {
  id: number;
  attributes: CourseLessonCommonAttributes & {
    teacher?: { data: Profile };
    availableTeacher?: { data: Profile[] };
    course?: { data: CourseResponse };
    slidePdf?: { data: Media };
    createdAt: string;
    updatedAt: string;
    lessonTemplate?: null;
  };
}

export enum TeachersAcceptanceStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}
