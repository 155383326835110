import axios from 'axios';
import qs from 'qs';
import { StrapiResponse } from '../interfaces/commons';

const instance = axios.create();

import {
  LessonTemplate,
  LessonTemplateAttributes,
  LessonTemplatesListResponse,
} from '../interfaces/lesson-templates';
import { lessonCategory } from '../constants/course';

const { REACT_APP_API_URL } = process.env;

export const getLessonTemplates = async (
  searchParams: any,
  token: string | null
) => {
  const selectedCategory = lessonCategory.filter(({ title }) =>
    searchParams.getAll('category').find((v: string) => v === title)
  );

  const categoryArr = selectedCategory.map((object) => object.id);

  const query = {
    populate: ['*', 'category'],
    sort: { updatedAt: 'desc' },
    filters: {
      title: { $contains: searchParams.get('search') },
      $and: [
        {
          category: { $in: categoryArr },
        },
        {
          endCourse: { $eq: searchParams.get('endCourse') },
        },
      ],
    },
    pagination: {
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize') || 100,
    },
  };

  const { data } = await axios.get<LessonTemplatesListResponse>(
    `${REACT_APP_API_URL}/lesson-templates?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const getLessonTemplate = async (
  lessonTemplateId: string | number,
  token: string | null
) => {
  const query = {
    populate: ['availableTeacher', 'teacherAutoAssignLessons'],
  };

  const { data } = await axios.get<StrapiResponse<LessonTemplate>>(
    `${REACT_APP_API_URL}/lesson-templates/${lessonTemplateId}?${qs.stringify(
      query
    )}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const postLessonTemplate = async (
  newLessonTemplate: LessonTemplateAttributes,
  token: string | null
) => {
  const { data } = await instance.post<StrapiResponse<LessonTemplate>>(
    `${REACT_APP_API_URL}/lesson-templates`,
    { data: newLessonTemplate },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const putLessonTemplate = async (
  lessonTemplateId: string | number,
  updatedLessonTemplate: LessonTemplateAttributes,
  token: string | null
) => {
  const { data } = await instance.put<any>(
    `${REACT_APP_API_URL}/lesson-templates/${lessonTemplateId}`,
    { data: updatedLessonTemplate },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const delLessonTemplate = async (
  lessonTemplateId: string | number,
  token: string | null
) => {
  const { data } = await instance.delete<any>(
    `${REACT_APP_API_URL}/lesson-templates/${lessonTemplateId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};
