import React, { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { FieldError } from 'react-hook-form';
import { CourseResponse } from '../../interfaces/courses';
import { SearchableSelect } from '../Form/SearchableSelect';
import { useQuery } from 'react-query';
import { getCourseDetail, listCoursesFilter } from '../../api/courses';
import { useAuth } from '../../contexts/Auth';
import { useDebounce } from 'use-debounce';

interface CourseSearchSelectProps {
  name: string;
  control?: Control<any>;
  label: string;
  error?: FieldError;
  defaultValue?: number;
  required?: boolean;
  onCourseSelect?: (course: CourseResponse) => void;
}

const CourseSearchSelect: React.FC<CourseSearchSelectProps> = ({
  name,
  label,
  control,
  error,
  defaultValue,
  required = false,
  onCourseSelect,
}) => {
  const [{ token }] = useAuth();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 300);
  const value = useWatch({ control, name }) as number;

  const query = useQuery(
    ['courses-select', debouncedQuery, value],
    async () => {
      const selected = value
        ? await getCourseDetail(value.toString(), token)
        : null;
      const courses = await listCoursesFilter({
        token,
        query: {
          filters: {
            title: {
              $contains: searchQuery,
            },
          },
          populate: {
            section: {
              fields: ['name'],
            },
          },
        },
      });

      if (!selected || !selected?.data) return courses;

      return {
        ...courses,
        data: [selected.data, ...courses.data],
      };
    }
  );

  const options = React.useMemo(() => {
    if (!query.data) return [];
    return query.data.data.map((course: CourseResponse) => ({
      value: course.id,
      label: course.attributes.title,
      data: course,
    }));
  }, [query.data]);

  const handleChange = (selectedOption: { data: CourseResponse } | null) => {
    if (onCourseSelect && selectedOption?.data) {
      onCourseSelect(selectedOption.data);
    }
  };

  const handleInput = (input: string) => {
    setSearchQuery(input);
  };

  return (
    <SearchableSelect
      name={name}
      label={label}
      control={control}
      error={error}
      options={options}
      onInput={handleInput}
      isLoading={query.isFetching}
      /*       defaultValue={defaultValue} */
      onChange={handleChange}
      registerOptions={{
        setValueAs: (value) => {
          return value.value;
        },
      }}
    />
  );
};

export default CourseSearchSelect;
