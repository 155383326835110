import * as yup from 'yup';
import { commaDecimalNumber } from '../../../libs/utils/helpers';

const personalInfoSchema = yup.object().shape({
  firstName: yup.string().required('Campo obbligatorio'),
  lastName: yup.string().required('Campo obbligatorio'),
  address: yup.string(),
  streetNumber: yup.string(),
  city: yup.string(),
  province: yup.string(),
  zip: yup.string(),
  phone: yup.string(),
  email: yup.string().email('Email non valida'),
  fiscalCode: yup.string(),
  iban: yup.string(),
});

const lessonPaymentSchema = yup.object().shape({
  courseName: yup.string().required('Campo obbligatorio'),
  lessonName: yup.string().required('Campo obbligatorio'),
  lessonDate: yup.string().required('Campo obbligatorio'),
  organizingSection: yup.string().required('Campo obbligatorio'),
});

const coursePaymentSchema = yup.object().shape({
  courseName: yup.string().required('Campo obbligatorio'),
  organizingSection: yup.string().required('Campo obbligatorio'),
});

export const adminLessonReimbursementSchema = yup.object().shape({
  profile: yup.number(),
  module: yup.object().shape({
    personalInfo: personalInfoSchema,
    payment: lessonPaymentSchema,
  }),
  totalCompensation: yup
    .number()
    .transform(commaDecimalNumber)
    .typeError("L'importo deve essere numerico ")
    .required('Campo obbligatorio'),
  totalReimbursement: yup
    .number()
    .transform(commaDecimalNumber)
    .typeError("L'importo deve essere numerico ")
    .required('Campo obbligatorio'),
  lesson: yup
    .number()
    .typeError('Seleziona una lezione')
    .required('Seleziona una lezione'),
});

export const adminCourseReimbursementSchema = yup.object().shape({
  profile: yup.number(),
  module: yup.object().shape({
    personalInfo: personalInfoSchema,
    payment: coursePaymentSchema,
  }),
  totalCompensation: yup
    .number()
    .transform(commaDecimalNumber)
    .typeError("L'importo deve essere numerico ")
    .required('Campo obbligatorio'),
  supervisorCourse: yup.number().when('module.payment.role', {
    is: 'supervisor',
    then: (schema) => schema.required('Seleziona un corso'),
    otherwise: (schema) => schema.nullable(),
  }),
  treasurerCourse: yup.number().when('module.payment.role', {
    is: 'treasurer',
    then: (schema) => schema.required('Seleziona un corso'),
    otherwise: (schema) => schema.nullable(),
  }),
});
