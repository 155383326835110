import { Text } from '@react-pdf/renderer';
import React from 'react';
import { ReimbursementStatus } from '../../interfaces/reimbursment';
import { styles } from './styles';

interface StatusBadgeProps {
  status: ReimbursementStatus | 'PAGATO';
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  let badgeStyle;
  let text;

  switch (status) {
    case ReimbursementStatus.APPROVED:
      badgeStyle = { ...styles.badge, ...styles.badgeGreen };
      text = 'Approvato';
      break;
    case ReimbursementStatus.REJECTED:
      badgeStyle = { ...styles.badge, ...styles.badgeRed };
      text = 'Documentazione incompleta';
      break;
    case ReimbursementStatus.IN_REVIEW:
      badgeStyle = { ...styles.badge, ...styles.badgeYellow };
      text = 'Da approvare';
      break;
    case ReimbursementStatus.WAITING_COMPLETION:
      badgeStyle = { ...styles.badge, ...styles.badgeGray };
      text = 'Bozza';
      break;
    case 'PAGATO':
      badgeStyle = { ...styles.badge, ...styles.badgeGreen };
      text = 'PAGATO';
      break;
    default:
      badgeStyle = { ...styles.badge, ...styles.badgeGray };
      text = 'Da approvare';
  }

  return <Text style={badgeStyle}>{text}</Text>;
};

export default StatusBadge;
