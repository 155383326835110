import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import SendButton from '../Buttons/SandButton';
import * as Yup from 'yup';

import InputPresentational from '../Form/InputPresentational';
import PriceInputPresentational from '../Form/PriceInputPresentational';
import { useMutation, useQueryClient } from 'react-query';
import {
  CourseLessonRequest,
  CourseLessonResponse,
} from '../../interfaces/lesson';
import { updateCourseLesson } from '../../api/lesson';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../contexts/Auth';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { isDelegato } from '../../libs/utils/auth';
import useYupValidationResolver from '../../libs/YupValidationResolver';

interface RegisterPaymentModalProps {
  lesson?: CourseLessonResponse;
}

const RegisterPaymentModal: React.FC<RegisterPaymentModalProps> = ({
  lesson,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [{ token, profile }] = useAuth();
  const { id: courseId } = useParams();
  const queryClient = useQueryClient();

  const toggleModal = () => {
    setOpen((v) => !v);
  };

  useEffect(() => {
    delete lesson?.attributes.slidePdf;
    reset({
      ...lesson?.attributes,
      course: lesson?.attributes.course?.data?.id,
      teacher: lesson?.attributes.teacher?.data?.id,
      availableTeacher: lesson?.attributes.availableTeacher?.data?.map(
        (t) => t.id
      ),
      actualPayment: lesson?.attributes.estimatePayment,
      actualRefund: lesson?.attributes.estimateRefund,
      estimatePaymentLimit: lesson?.attributes.estimatePaymentLimit,
      reimbursement: lesson?.attributes.reimbursement?.data?.id,
    });
  }, [lesson]);

  const { mutate: updateLesson, isLoading: isUpdatingLesdston } = useMutation(
    'updateLessonPayments',
    updateCourseLesson,
    {
      onSuccess: () => {
        toast.success('Lezione aggiornata con successo');
        queryClient.invalidateQueries({ queryKey: ['getTeacherLessons'] });
        queryClient.invalidateQueries({ queryKey: ['lessons', courseId] });
        queryClient.invalidateQueries({ queryKey: ['course'] });
        toggleModal();
      },
      onError: (err) => {
        toast.error((err as string) || 'Ooops... qualcosa è andato storto');
      },
    }
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CourseLessonRequest>({
    resolver: useYupValidationResolver(
      Yup.object().shape({
        actualPayment: Yup.number()
          .min(0)
          .typeError('Valore non valido')
          .optional(),
        actualRefund: Yup.number()
          .min(0)
          .typeError('Valore non valido')
          .required('Questo campo è obbligatorio'),
        paymentDate: Yup.date()
          .transform((value: Date) => {
            const withoutTimezone = new Date(
              Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
            );
            return withoutTimezone;
          })
          .typeError('Valore non valido')
          .required('Questo campo è obbligatorio'),
      })
    ),
  });

  const onSubmit = (values: CourseLessonRequest) => {
    updateLesson({ token, id: lesson?.id + '', body: values });
  };

  const onChangeActualPayment = (value: number) => {
    const limit = Number(watch('estimatePaymentLimit') || 0);

    if (isDelegato(profile) && Number(value) > limit) {
      setValue('actualPayment', limit);
      toast.error(
        "L'importo può essere aumentato solo dalla Segreteria Nazionale"
      );
      return;
    } else if (isDelegato(profile) && Number(value) <= limit) {
      setValue('actualPayment', value);
    }

    if (!isDelegato(profile)) setValue('actualPayment', value);
  };

  return (
    <>
      {!isDelegato(profile) && (
        <UnderlineButton
          small
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <CurrencyEuroIcon className='w-4 h-4' /> Registra pagamento
        </UnderlineButton>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div
            className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'
            onClick={(e) => e.stopPropagation()}
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Registra pagamento docente
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='bg-neutral-100 px-4 py-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    <InputPresentational
                      id='paymentDate'
                      label='Data'
                      name='paymentDate'
                      type='date'
                      required
                      register={register}
                      error={errors.paymentDate}
                    />
                    <PriceInputPresentational
                      id='actualRefund'
                      label='Rimborso docente'
                      name='actualRefund'
                      type='text'
                      defaultValue={lesson?.attributes.estimateRefund}
                      error={errors.actualRefund}
                      register={register}
                    />
                    <PriceInputPresentational
                      id='actualPayment'
                      label='Compenso docente'
                      name='actualPayment'
                      type='text'
                      defaultValue={lesson?.attributes.estimatePayment}
                      register={register}
                      // onChange={(e) =>
                      //   onChangeActualPayment(Number(e.target.value))
                      // }
                      error={errors.actualPayment}
                    />
                  </div>

                  <div className='flex items-center justify-end gap-4 mt-6'>
                    <PrimaryButton textSmall onClick={toggleModal}>
                      Annulla
                    </PrimaryButton>
                    <SendButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      smallRadius
                      disabled={isUpdatingLesdston}
                    >
                      Registra pagamento
                    </SendButton>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RegisterPaymentModal;
