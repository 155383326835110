export const formatCurrency = (value: number | undefined | null) => {
  if (!value) return '€ 0,00';
  return (
    '€ ' +
    value.toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};
