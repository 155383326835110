import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { ReimbursementAttributes } from '../../../interfaces/reimbursment';
import InputPresentational from '../../Form/InputPresentational';
import AdminBaseReimbursementFormModal from './AdminBaseReimbursementFormModal';
import { useReimbursementForm } from './hooks/useReimbursementForm';
import { adminCourseReimbursementSchema } from './validation';
import SelectPresentational from '../../Form/SelectPresentational';
import CourseSearchSelect from '../../FetchSelect/CourseSearchSelect';
import { CourseResponse } from '../../../interfaces/courses';

interface AdminCourseReimbursementFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ReimbursementAttributes, files: File[]) => Promise<void>;
  initialData?: Partial<ReimbursementAttributes>;
  isEdit?: boolean;
}

const ROLE_OPTIONS = [
  { value: 'supervisor', label: 'Responsabile' },
  { value: 'treasurer', label: 'Tesoriere' },
];

const AdminCourseReimbursementFormModal: React.FC<
  AdminCourseReimbursementFormModalProps
> = ({ isOpen, onClose, onSubmit, initialData, isEdit = false }) => {
  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue,
    control,
    isSubmitting,
    files,
    handleFileChange,
    handleDrop,
    handleRemovePending,
  } = useReimbursementForm({
    initialData,
    onSubmit,
    onClose,
    resolver: yupResolver(adminCourseReimbursementSchema),
  });

  const supervisorCourse = watch('supervisorCourse');
  const treasurerCourse = watch('treasurerCourse');
  const [selectedRole, setSelectedRole] = useState<'supervisor' | 'treasurer'>(
    supervisorCourse ? 'supervisor' : 'treasurer'
  );

  const courseId =
    selectedRole === 'supervisor' ? supervisorCourse : treasurerCourse;

  const handleCourseSelect = (course: CourseResponse) => {
    setValue('module.payment.courseName', course.attributes.title || '');
    setValue(
      'module.payment.organizingSection',
      course.attributes.section?.data?.attributes?.name || ''
    );
  };

  return (
    <AdminBaseReimbursementFormModal
      control={control}
      isOpen={isOpen}
      onClose={onClose}
      title={
        isEdit
          ? 'Modifica rendicontazione corso'
          : 'Nuova rendicontazione corso'
      }
      isEdit={isEdit}
      register={register}
      errors={errors}
      watch={watch}
      setValue={setValue}
      isSubmitting={isSubmitting}
      files={files}
      handleSubmit={handleSubmit}
      handleFileChange={handleFileChange}
      handleDrop={handleDrop}
      handleRemovePending={handleRemovePending}
    >
      {/* Course Selection */}

      <div className='bg-white p-6 rounded-lg space-y-4'>
        <h4 className='text-lg font-medium text-gray-900'>Seleziona Corso</h4>
        <SelectPresentational
          name='role'
          label='Ruolo *'
          options={ROLE_OPTIONS}
          defaultValue={selectedRole}
          onChangeValue={(e) => {
            const role = e?.value;
            setSelectedRole(role);
            if (role === 'supervisor') {
              setValue('supervisorCourse', courseId);
              setValue('treasurerCourse', null);
            } else if (role === 'treasurer') {
              setValue('treasurerCourse', courseId);
              setValue('supervisorCourse', null);
            }
          }}
        />
        {selectedRole && (
          <CourseSearchSelect
            name={
              selectedRole === 'supervisor'
                ? 'supervisorCourse'
                : 'treasurerCourse'
            }
            label='Corso'
            control={control}
            error={
              selectedRole === 'supervisor'
                ? errors.supervisorCourse
                : errors.treasurerCourse
            }
            required
            onCourseSelect={handleCourseSelect}
          />
        )}
      </div>

      {/* Payment Information */}
      <div className='bg-white p-6 rounded-lg space-y-4'>
        <h4 className='text-lg font-medium text-gray-900'>Dati Pagamento</h4>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <InputPresentational
            className='col-span-full'
            id='totalCompensation'
            label='Importo compenso *'
            name='totalCompensation'
            type='text'
            register={register}
            error={errors.totalCompensation}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.courseName'
            label='Nome corso *'
            name='module.payment.courseName'
            type='text'
            register={register}
            error={errors.module?.payment?.courseName}
            readOnly={true}
          />
          <InputPresentational
            className='opacity-70'
            id='module.payment.organizingSection'
            label='Sezione organizzatrice *'
            name='module.payment.organizingSection'
            type='text'
            register={register}
            error={errors.module?.payment?.organizingSection}
            readOnly={true}
          />
        </div>
      </div>
    </AdminBaseReimbursementFormModal>
  );
};

export default AdminCourseReimbursementFormModal;
