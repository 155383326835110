import {
  DocumentTextIcon,
  PaperClipIcon,
  PencilIcon,
} from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  updateAdminLessonReimbursement,
  updateReimbursement,
} from '../../../api/reimbursement';
import Badge from '../../../components/Badge';
import BaseModal from '../../../components/Modals/BaseModal';
import AdminLessonReimbursementFormModal from '../../../components/Modals/ReimbursmentFormModal/AdminLessonReimbursementFormModal';
import { prepareReimbursementFormData } from '../../../components/Modals/ReimbursmentFormModal/utils';
import { useAuth } from '../../../contexts/Auth';
import {
  LessonReimbursementModule,
  Reimbursement,
  ReimbursementAttributes,
  ReimbursementStatus,
} from '../../../interfaces/reimbursment';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import { formatDate } from '../../../libs/utils/formatters/datetimeFormatter';
import PDFExportButton from '../../../components/PDFExport/PDFExportButton';
import LessonReimbursementPDF from '../../../components/PDFExport/LessonReimbursementPDF';

interface LessonReimbursementRowProps {
  reimbursement: Reimbursement;
  searchWords: string[];
}

const LessonReimbursementRow: React.FC<LessonReimbursementRowProps> = ({
  reimbursement,
}) => {
  const [{ token }] = useAuth();
  const queryClient = useQueryClient();
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [note, setNote] = useState(reimbursement.attributes.note || '');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStatusChange = async (newStatus: ReimbursementStatus) => {
    try {
      await updateReimbursement({
        id: reimbursement.id,
        data: {
          status: newStatus,
        },
        token,
      });
      queryClient.invalidateQueries('lesson-reimbursements');
      toast.success('Stato aggiornato con successo');
    } catch (error) {
      toast.error("Errore durante l'aggiornamento dello stato");
    }
  };

  const handleNoteSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateReimbursement({
        id: reimbursement.id,
        data: {
          note,
        },
        token,
      });
      queryClient.invalidateQueries('lesson-reimbursements');
      toast.success('Nota aggiornata con successo');
      setIsNoteModalOpen(false);
    } catch (error) {
      toast.error("Errore durante l'aggiornamento della nota");
    }
    setIsSubmitting(false);
  };

  const reimbursementModule = reimbursement.attributes
    .module as LessonReimbursementModule;

  const updateMutation = useMutation(
    (params: { id: number; data: FormData | object }) =>
      updateAdminLessonReimbursement(params.id, params.data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('lesson-reimbursements');
        setIsEditModalOpen(false);
      },
    }
  );

  const handleEdit = async (data: ReimbursementAttributes, files: File[]) => {
    data.module.payment.amount = data.totalCompensation;
    const formData = prepareReimbursementFormData(data, files);
    await updateMutation.mutateAsync({
      id: reimbursement.id,
      data: formData,
    });
  };

  const renderModuleContent = () => {
    if (!reimbursementModule) return null;
    const expensesTotal =
      reimbursementModule.refund?.expenses?.length > 0
        ? reimbursementModule.refund?.expenses?.reduce(
            (sum, expense) => sum + (expense.amount || 0),
            0
          )
        : reimbursement.attributes.totalReimbursement ?? 0;

    const paymentAmount = reimbursementModule.payment?.amount || 0;

    const teachersExpensesTotal = reimbursementModule.refund?.expenses?.reduce(
      (sum, expense) =>
        expense.recipient === 'Docente' ? sum + (expense.amount || 0) : sum,
      0
    );

    const onavExpensesTotal = reimbursementModule.refund?.expenses?.reduce(
      (sum, expense) =>
        expense.recipient === 'ONAV' ? sum + (expense.amount || 0) : sum,
      0
    );

    return (
      <div className='space-y-8'>
        {/* Personal Information */}
        <div>
          <div className='flex flex-row justify-between items-center'>
            <h4 className='text-lg font-medium text-gray-900 mb-4'>
              Dettagli Lezione
            </h4>
            {reimbursement.attributes.lesson?.data?.attributes?.paymentDate && (
              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Pagato
              </span>
            )}
            {!reimbursement.attributes.lesson?.data?.attributes?.paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.WAITING_COMPLETION && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800'>
                  Rendicontazione da compilare
                </span>
              )}
            {!reimbursement.attributes.lesson?.data?.attributes?.paymentDate &&
              (reimbursement.attributes.status ===
                ReimbursementStatus.IN_REVIEW ||
                reimbursement.attributes.status === null) && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800'>
                  Rendicontazione in attesa di approvazione
                </span>
              )}
            {!reimbursement.attributes.lesson?.data?.attributes?.paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.APPROVED && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                  Rendicontazione approvata
                </span>
              )}
            {!reimbursement.attributes.lesson?.data?.attributes?.paymentDate &&
              reimbursement.attributes.status ===
                ReimbursementStatus.REJECTED && (
                <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                  Rendicontazione con documenti mancanti
                </span>
              )}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-8'>
            <div>
              <span className='text-sm text-gray-500'>Titolo</span>
              <p className='text-sm text-gray-900'>
                {reimbursement.attributes.lesson?.data?.attributes?.title}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Data</span>
              <p className='text-sm text-gray-900'>
                {formatDate(
                  reimbursement.attributes.lesson?.data?.attributes?.date,
                  'LL • HH:mm'
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Compenso preventivo</span>
              <p className='text-sm text-gray-900'>
                {formatCurrency(
                  reimbursement.attributes.lesson?.data?.attributes
                    ?.estimatePayment
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Rimborso preventivo</span>
              <p className='text-sm text-gray-900'>
                {formatCurrency(
                  reimbursement.attributes.lesson?.data?.attributes
                    ?.estimateRefund
                )}
              </p>
            </div>
          </div>

          <h4 className='text-lg font-medium text-gray-900 mb-4'>
            Dati Personali
          </h4>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <span className='text-sm text-gray-500'>Nome e Cognome</span>
              <p className='text-sm text-gray-900'>{`${reimbursementModule.personalInfo?.firstName} ${reimbursementModule.personalInfo?.lastName}`}</p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Codice Fiscale</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.fiscalCode}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Indirizzo</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.address}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Civico</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.streetNumber}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Città</span>
              <p className='text-sm text-gray-900'>{`${reimbursementModule.personalInfo?.city} (${reimbursementModule.personalInfo?.province}) ${reimbursementModule.personalInfo?.zip}`}</p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Email</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.email}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>Telefono</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.phone}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>IBAN</span>
              <p className='text-sm text-gray-900'>
                {reimbursementModule.personalInfo?.iban}
              </p>
            </div>
          </div>
        </div>

        {/* Payment Information */}
        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>Compenso</h4>
          {reimbursementModule.payment?.waivePayment ? (
            <p className='text-sm text-gray-500'>
              Il docente ha rinunciato al compenso
            </p>
          ) : (
            <div className='space-y-4'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <span className='text-sm text-gray-500'>Importo</span>
                  <p className='text-sm text-gray-900'>
                    {formatCurrency(reimbursementModule.payment?.amount || 0)}
                  </p>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>Corso</span>
                  <p className='text-sm text-gray-900'>
                    {reimbursementModule.payment?.courseName}
                  </p>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>Data Lezione</span>
                  <p className='text-sm text-gray-900'>
                    {formatDate(
                      reimbursementModule.payment?.lessonDate || '',
                      'L'
                    )}
                  </p>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>Sezione</span>
                  <p className='text-sm text-gray-900'>
                    {reimbursementModule.payment?.organizingSection}
                  </p>
                </div>
              </div>

              <div className='space-y-2'>
                <h5 className='text-sm font-medium text-gray-900'>
                  Dichiarazioni
                </h5>
                <div className='space-y-1'>
                  <p className='text-sm text-gray-500'>
                    Dipendente pubblico:{' '}
                    {reimbursementModule.payment?.declarations
                      ?.isPublicEmployee === 'YES'
                      ? 'Sì'
                      : 'No'}
                  </p>
                  {Object.entries(
                    reimbursementModule.payment?.declarations?.autonomousWork ||
                      {}
                  ).map(([key, value]) => (
                    <p key={key} className='text-sm text-gray-500'>
                      {value ? '✓' : '✗'}{' '}
                      {
                        {
                          noTimeConstraints:
                            'Il sottoscritto non ha avuto obblighi di orario',
                          discretionaryExecution:
                            "Il sottoscritto è stato pienamente libero di svolgere l'incarico con discrezionalità nei riguardi delle modalità e dei tempi di esecuzione",
                          independentCriteria:
                            "I criteri seguiti nell'effettuare la prestazione sono stati del tutto indipendenti e personali, avendo il richiedente solo indicato il contenuto della prestazione richiesta",
                          agreedCompensation:
                            "la remunerazione è quella dovuta sulla base dell'offerta concordata",
                        }[key]
                      }
                    </p>
                  ))}

                  {Object.entries(
                    reimbursementModule.payment?.declarations || {}
                  )
                    .filter(
                      ([key]) =>
                        ![
                          'autonomousWork',
                          'isPublicEmployee',
                          'year',
                        ].includes(key)
                    )
                    .map(([key, value]) => (
                      <p key={key} className='text-sm text-gray-500'>
                        {value ? '✓' : '✗'}{' '}
                        {
                          {
                            taskCompletion:
                              "di aver svolto l'incarico/chi sopra riportato/i",
                            vatExemption:
                              "che non esercito per professione abituale attività di lavoro autonomo e che il compenso non è soggetto ad IVA (escluso ai sensi dell'art.5 del D.P.R. 633/72)",
                            taxWithholding:
                              "che il compenso è soggetto a ritenuta di acconto ai sensi dell'art.25 del D.P.R. 600/73",
                            socialSecurity:
                              "che tale compenso non è assoggettato a contributo previdenziale ai sensi dell'art. 44 D.L. 269/209 in quanto nel corso dell'anno solare sotto indicato il totale dei compensi ricevuti dal prestatore non supera i 5.000,00€",
                            noEmploymentClaim:
                              'che la suddetta prestazione resa occasionalmente, esclude da parte del dichiarante qualsiasi pretesa di riconoscimento di rapporto di lavoro dipendente',
                          }[key]
                        }
                        {key === 'socialSecurity' && (
                          <span className='text-sm text-gray-500 ml-2'>
                            (Anno{' '}
                            {reimbursementModule.payment?.declarations?.year})
                          </span>
                        )}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>Spese</h4>
          {reimbursementModule.refund?.waiveRefund ? (
            <p className='text-sm text-gray-500'>
              Il docente ha rinunciato al rimborso spese
            </p>
          ) : (
            <div className='space-y-4'>
              {reimbursementModule.refund?.expenses?.map((expense, index) => (
                <div key={index} className='bg-gray-50 p-4 rounded-lg'>
                  <div className='grid grid-cols-2 gap-4'>
                    <div>
                      <span className='text-sm text-gray-500'>Tipo</span>
                      <p className='text-sm text-gray-900'>{expense.type}</p>
                    </div>
                    <div>
                      <span className='text-sm text-gray-500'>Data</span>
                      <p className='text-sm text-gray-900'>
                        {formatDate(expense.startDate, 'L')}
                        {expense.endDate &&
                          ` - ${formatDate(expense.endDate, 'L')}`}
                      </p>
                    </div>
                    <div>
                      <span className='text-sm text-gray-500'>Descrizione</span>
                      <p className='text-sm text-gray-900'>
                        {expense.description}
                      </p>
                    </div>
                    <div>
                      <span className='text-sm text-gray-500'>
                        Intestata a:
                      </span>
                      <p className='text-sm text-gray-900'>
                        {expense.recipient}
                      </p>
                    </div>
                    <div>
                      <span className='text-sm text-gray-500'>Importo</span>
                      <p className='text-sm text-gray-900'>
                        {formatCurrency(expense.amount)}
                      </p>
                    </div>
                    {expense.type === 'Viaggio auto' && (
                      <>
                        <div>
                          <span className='text-sm text-gray-500'>Auto</span>
                          <p className='text-sm text-gray-900'>
                            {expense.carModel} - {expense.licensePlate}
                          </p>
                        </div>
                        <div>
                          <span className='text-sm text-gray-500'>
                            Kilometri
                          </span>
                          <p className='text-sm text-gray-900'>
                            {expense.kilometers} km
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
              {/*               <div>
                <span className='text-sm text-gray-500'>Totale Rimborso</span>
                <p className='text-sm font-medium text-gray-900'>
                  {formatCurrency(reimbursementModule.refund?.totalAmount || 0)}
                </p>
              </div> */}
            </div>
          )}
        </div>

        <div className='bg-white shadow overflow-hidden sm:rounded-lg p-6'>
          <h4 className='text-lg font-IBM text-gray-600 mb-6'>
            Riepilogo Totali
          </h4>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
            <div>
              <span className='text-sm text-gray-500'>
                TOTALE LORDO COMPENSO
              </span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(paymentAmount)}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>
                TOTALE LORDO RIMBORSO
              </span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(expensesTotal)}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>TOTALE LORDO</span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(paymentAmount + expensesTotal)}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>RITENUTA D'ACCONTO</span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(
                  reimbursementModule.payment?.waivePayment
                    ? 0
                    : (paymentAmount +
                        (reimbursementModule.refund?.waiveRefund
                          ? 0
                          : teachersExpensesTotal)) *
                        0.2
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>TOTALE NETTO</span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(
                  reimbursementModule.payment?.waivePayment
                    ? reimbursementModule.refund?.waiveRefund
                      ? 0
                      : expensesTotal
                    : (paymentAmount +
                        (reimbursementModule.refund?.waiveRefund
                          ? 0
                          : teachersExpensesTotal)) *
                        0.8
                )}
              </p>
            </div>
            <div>
              <span className='text-sm text-gray-500'>
                FATTURE INTESTATE A ONAV
              </span>
              <p className='text-sm font-medium text-gray-900'>
                {formatCurrency(onavExpensesTotal)}
              </p>
            </div>
          </div>
        </div>

        {/* Signature */}
        <div>
          <h4 className='text-lg font-medium text-gray-900 mb-4'>Firma</h4>
          <p className='text-sm text-gray-900'>
            {reimbursementModule.signature}
          </p>
        </div>
      </div>
    );
  };

  const profile = reimbursement.attributes.profile?.data?.attributes;
  const { name, surname } = profile
    ? profile
    : {
        name: reimbursement.attributes.module.personalInfo?.firstName,
        surname: reimbursement.attributes.module.personalInfo?.lastName,
      };

  return (
    <>
      <tr>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {`${surname} ${name}`}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatDate(reimbursement.attributes.createdAt, 'L')}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursementModule?.payment?.organizingSection || ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursementModule?.payment?.courseName || ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursementModule?.payment?.lessonName || ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatDate(reimbursementModule?.payment?.lessonDate || '', 'L')}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatCurrency(reimbursement.attributes.totalCompensation)}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {formatCurrency(reimbursement.attributes.totalReimbursement)}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursement.attributes.lesson?.data?.attributes?.paymentDate
            ? formatDate(
                reimbursement.attributes.lesson?.data?.attributes.paymentDate,
                'L'
              )
            : ''}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursement.attributes.lesson?.data?.attributes?.paymentDate ? (
            <select
              disabled
              className='rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm bg-green-50 text-green-800'
              value='PAGATO'
            >
              <option value='PAGATO'>PAGATO</option>
            </select>
          ) : (
            <select
              value={reimbursement.attributes.status as unknown as string}
              onChange={(e) =>
                handleStatusChange(e.target.value as ReimbursementStatus)
              }
              className='rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm'
            >
              <option value={ReimbursementStatus.IN_REVIEW}>
                Da approvare
              </option>
              <option value={ReimbursementStatus.WAITING_COMPLETION}>
                Bozza
              </option>
              <option value={ReimbursementStatus.REJECTED}>
                Documentazione incompleta
              </option>
              <option value={ReimbursementStatus.APPROVED}>Approvato</option>
            </select>
          )}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursement.attributes.attachments?.data?.length && (
            <button
              onClick={() => setIsAttachmentsModalOpen(true)}
              className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
            >
              <PaperClipIcon className='h-4 w-4' />
              <span>{reimbursement.attributes.attachments?.data?.length}</span>
            </button>
          )}
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          <button
            onClick={() => setIsNoteModalOpen(true)}
            className='text-primary hover:text-primary-dark'
          >
            {reimbursement.attributes.note ? 'Modifica nota' : 'Aggiungi nota'}
          </button>
        </td>
        <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
          {reimbursementModule && (
            <div className='flex items-center gap-2'>
              <button
                onClick={() => setIsModuleModalOpen(true)}
                className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
              >
                <DocumentTextIcon className='h-4 w-4' />
                <span>Visualizza</span>
              </button>
              <button
                onClick={() => setIsEditModalOpen(true)}
                className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
              >
                <PencilIcon className='h-4 w-4' />
                <span>Modifica</span>
              </button>
              <PDFExportButton
                document={
                  <LessonReimbursementPDF reimbursement={reimbursement} />
                }
                fileName={`rimborso-lezione-${
                  reimbursement.attributes.lesson?.data?.attributes?.title
                    ? reimbursement.attributes.lesson.data.attributes.title
                        .replace(/[^a-z0-9]/gi, '-')
                        .toLowerCase()
                    : reimbursement.id
                }.pdf`}
              />
            </div>
          )}
        </td>
      </tr>

      {/* Note Modal */}
      <BaseModal
        isOpen={isNoteModalOpen}
        toggle={() => setIsNoteModalOpen(false)}
        title={
          reimbursement.attributes.note ? 'Modifica nota' : 'Aggiungi nota'
        }
        onConfirm={handleNoteSubmit}
        onCancel={() => setIsNoteModalOpen(false)}
        isLoading={isSubmitting}
      >
        <textarea
          rows={4}
          className='shadow-sm block w-full focus:ring-primary focus:border-primary sm:text-sm border border-gray-300 rounded-md'
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </BaseModal>

      {/* Attachments Modal */}
      <BaseModal
        isOpen={isAttachmentsModalOpen}
        toggle={() => setIsAttachmentsModalOpen(false)}
        title='Lista allegati'
        hideBottom
      >
        <div className='space-y-4'>
          {reimbursement.attributes.attachments?.data?.map(
            (attachment, index) => (
              <div key={index} className='flex items-center justify-between'>
                <span className='text-sm text-gray-600'>
                  {attachment.attributes.name}
                </span>
                <div className='flex items-center gap-4'>
                  <a
                    href={attachment.attributes.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => {
                      e.preventDefault();
                      // Create a server request to download the file
                      fetch(attachment.attributes.url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          // Create a blob URL and trigger download
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute(
                            'download',
                            attachment.attributes.name
                          );
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                          window.URL.revokeObjectURL(url);
                        })
                        .catch((error) =>
                          console.error('Download failed:', error)
                        );
                    }}
                    className='text-primary hover:text-primary-dark text-sm font-medium'
                  >
                    Scarica
                  </a>
                  <a
                    href={attachment.attributes.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-primary hover:text-primary-dark text-sm font-medium'
                  >
                    Apri
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </BaseModal>

      {/* Module Modal */}
      <BaseModal
        isOpen={isModuleModalOpen}
        toggle={() => setIsModuleModalOpen(false)}
        title='Dettagli Modulo'
        hideBottom
      >
        <div className='max-h-[80vh] overflow-y-auto px-4'>
          {renderModuleContent()}
        </div>
      </BaseModal>

      {/* Edit Modal */}
      <AdminLessonReimbursementFormModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={handleEdit}
        key={isEditModalOpen ? 'open' : 'closed'}
        initialData={{
          ...reimbursement.attributes,
          lesson: reimbursement.attributes.lesson?.data?.id,
          profile: reimbursement.attributes.profile?.data?.id,
          lessonCourse:
            reimbursement.attributes.lesson?.data?.attributes?.course?.data?.id,
        }}
        isEdit
      />
    </>
  );
};

export default LessonReimbursementRow;
