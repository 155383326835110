import { DeepPartial } from 'react-hook-form';
import { CourseLessonCommonAttributes } from './lesson';
import { CourseAttributes } from './courses';

export enum ReimbursementStatus {
  WAITING_COMPLETION = 'WAITING_COMPLETION',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

interface PersonalInfo {
  lastName: string;
  firstName: string;
  address: string;
  streetNumber: string;
  city: string;
  province: string;
  zip: string;
  email: string;
  phone: string;
  fiscalCode: string;
  iban: string;
}

interface BasePaymentDeclarations {
  isPublicEmployee: 'YES' | 'NO';
  taskCompletion: boolean;
  vatExemption: boolean;
  taxWithholding: boolean;
  socialSecurity: boolean;
  year: string;
  autonomousWork: {
    noTimeConstraints: boolean;
    discretionaryExecution: boolean;
    independentCriteria: boolean;
    agreedCompensation: boolean;
  };
  noEmploymentClaim: boolean;
}

interface LessonPayment {
  waivePayment: boolean;
  amount?: number;
  courseName: string;
  lessonDate: string;
  lessonName: string;
  organizingSection: string;
  declarations: BasePaymentDeclarations;
}

interface CoursePayment {
  waivePayment: boolean;
  amount?: number;
  courseName: string;
  courseStartDate: string;
  organizingSection: string;
  declarations: BasePaymentDeclarations;
}

interface LessonExpense {
  type: string;
  startDate: string;
  endDate?: string;
  description: string;
  carModel?: string;
  licensePlate?: string;
  kilometers?: number;
  recipient: 'ONAV' | 'Docente';
  amount: number;
}

interface LessonRefund {
  waiveRefund: boolean;
  expenses: LessonExpense[];
  totalAmount: number;
  receiptDeclaration: boolean;
}

export interface LessonReimbursementModule {
  personalInfo: PersonalInfo;
  payment: LessonPayment;
  refund: LessonRefund;
  signature: string;
}

export interface CourseReimbursementModule {
  personalInfo: PersonalInfo;
  payment: CoursePayment;
  signature: string;
}

export interface Attachment {
  id: number;
  attributes: {
    name: string;
    url: string;
  };
}

export interface ReimbursementAttributes {
  reimbursementViaInvoice?: boolean;
  createdAt: string;
  status: ReimbursementStatus;
  module: {
    personalInfo: PersonalInfo;
    payment: LessonPayment;
    refund?: LessonRefund;
    signature?: string;
  };
  note?: string;
  totalCompensation?: number;
  totalReimbursement?: number;
  attachments?: {
    data: Attachment[];
  };
  lesson?: number | null;
  lessonCourse?: number | null;
  supervisorCourse?: number | null;
  treasurerCourse?: number | null;
  profile?: number;
}

export interface Reimbursement {
  id: number;
  attributes: Omit<ReimbursementAttributes, 'lesson' | 'profile'> & {
    updatedAt: string;
    createdAt: string;
    lesson?: {
      data: {
        id: number;
        attributes:
          | (CourseLessonCommonAttributes & {
              course: { data: { id: number | null } };
            })
          | null;
      };
    };
    treasurerCourse?: {
      data: { id: number; attributes: CourseAttributes | null };
    };
    supervisorCourse?: {
      data: { id: number; attributes: CourseAttributes | null };
    };
    profile: {
      data: {
        id: number;
        attributes: any; // Profile type should be defined elsewhere
      };
    };
  };
}

export interface AdminReimbursementFormData {
  personalInfo: {
    firstName: string;
    lastName: string;
    address: string;
    streetNumber: string;
    city: string;
    province: string;
    zip: string;
    phone: string;
    email: string;
    fiscalCode: string;
    iban: string;
  };
  payment: {
    amount: number;
    courseName: string;
    organizingSection: string;
    paymentDate?: string;
  };
  totalReimbursement?: number;
  note?: string;
  attachments?: Array<{ id: number; name: string; url: string }>;
}

export interface AdminLessonReimbursementFormData
  extends AdminReimbursementFormData {
  payment: AdminReimbursementFormData['payment'] & {
    lessonName: string;
    lessonDate: string;
  };
}

export interface AdminCourseReimbursementFormData
  extends AdminReimbursementFormData {
  payment: AdminReimbursementFormData['payment'] & {
    courseStartDate: string;
    role: 'supervisor' | 'treasurer';
  };
}
