import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { DocumentDownloadIcon } from '@heroicons/react/solid';

interface PDFExportButtonProps {
  document: React.ReactElement;
  fileName: string;
}

const PDFExportButton: React.FC<PDFExportButtonProps> = ({
  document,
  fileName,
}) => {
  return (
    <PDFDownloadLink
      document={document}
      fileName={fileName}
      className='inline-flex items-center gap-1 px-2 py-1 text-primary hover:text-primary-dark rounded-md hover:bg-gray-50'
    >
      {({ loading, error }) => (
        <>
          <DocumentDownloadIcon className='h-5 w-5' />
          <span>
            {loading
              ? 'Generazione PDF...'
              : error
              ? 'PDF non disponibile'
              : 'Esporta PDF'}
          </span>
        </>
      )}
    </PDFDownloadLink>
  );
};

export default PDFExportButton;
